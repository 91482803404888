/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Button from 'components/core/button';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSidebarJsonViewer } from 'components/sidebars/sidebar-json-viewer';
import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import KybFilesTableAnt from 'components/tables/kyb-files-table';
import { useModalSyncKyb } from './modal-sync-kyb';
import { useUploadDocumentKyb } from './modal-upload-document';
import { useSidebarCompanyInfoEdit } from 'components/sidebars/sidebar-company-info-edit';
import IndividualsTable from 'components/tables/individuals-table';
import { Individual } from 'api/auth';
import { useSidebarIndividualEdit } from 'components/sidebars/sidebar-individual-edit';
import { ExpandedRow } from './row';
import { useDeleteDocumentKyb } from './modal-delete-document';

const UserDetailsKybTab = observer(() => {
  const userDetailsPageStore = useUserDetailsPageStore();
  const { showJsonViewerSidebar } = useSidebarJsonViewer();
  const { showSyncKybModal } = useModalSyncKyb();
  const { showDeleteModal } = useDeleteDocumentKyb();
  const { showUploadModal } = useUploadDocumentKyb();
  const { showEditCompanyInfoSidebar } = useSidebarCompanyInfoEdit({
    onChange: (info) => userDetailsPageStore.updateCompanyInfo(info),
  });
  const { showEditIndividualSidebar } = useSidebarIndividualEdit({
    onSubmit: (info) =>
      userDetailsPageStore.adminUpdateCompanyInfoIndividual(info),
  });
  const intl = useIntl();

  const { page, filter } = useParseCollectionQueryParams({
    collection: userDetailsPageStore.kybFiles,
  });

  const onApplyMeta = () => {
    showJsonViewerSidebar(
      userDetailsPageStore.kybVerifications?.meta
        ? userDetailsPageStore.kybVerifications?.meta
        : '{}'
    );
  };

  const onApplyStatus = async () => {
    const result = await userDetailsPageStore.getVerificationKyb(
      userDetailsPageStore.kybVerifications?.verifications?.[0] || ''
    );
    showJsonViewerSidebar(result ? JSON.stringify(result) : '{}');
  };

  const onApplyInd = (value: Individual) => {
    showJsonViewerSidebar(JSON.stringify(value));
  };

  const handleVerification = useCallback(
    (callback: (verification: string) => void) => {
      const kybVerification = userDetailsPageStore.kybVerifications;
      if (
        kybVerification !== null &&
        typeof kybVerification !== 'undefined' &&
        kybVerification.verifications.length > 0
      ) {
        callback(kybVerification.verifications[0]);
      }
    },
    [userDetailsPageStore]
  );

  const onSync = useCallback(() => {
    handleVerification(showSyncKybModal);
    handleVerification(userDetailsPageStore.syncVerificationKyb);
  }, [userDetailsPageStore, handleVerification, showSyncKybModal]);

  useEffect(() => {
    userDetailsPageStore.kybFiles.fetchData(filter, page);
  }, [userDetailsPageStore.kybFiles, filter, page]);

  useEffect(() => {
    userDetailsPageStore.fetchKybData();
  }, [userDetailsPageStore]);

  if (!userDetailsPageStore.kybVerifications) {
    return (
      <div className='text-center mt-12 text-text-70'>
        {intl.formatMessage({
          id: 'page.user_details.kyb.no_info',
          defaultMessage: 'No KYB information available',
        })}
      </div>
    );
  }

  return (
    <div className={styles.col}>
      <div className={styles.card}>
        <h2 className={styles.title}>
          {intl.formatMessage({
            id: 'page.user_details.kyb.companyInfo',
            defaultMessage: 'Company Info',
          })}
        </h2>
        <div className={styles.row}>
          <h3>
            {intl.formatMessage({
              id: 'page.user_details.kyb.companyName',
              defaultMessage: 'Company Name',
            })}
          </h3>
          {userDetailsPageStore.kybVerifications.companyName}
        </div>
        <div className={styles.row}>
          <h3>
            {intl.formatMessage({
              id: 'page.user_details.kyb.id',
              defaultMessage: 'ID',
            })}
          </h3>
          {userDetailsPageStore.kybVerifications.id}
        </div>
        <div className={styles.row}>
          <h3>
            {intl.formatMessage({
              id: 'page.user_details.kyb.verificationId',
              defaultMessage: 'Verification ID',
            })}
          </h3>
          {userDetailsPageStore.kybVerifications.verifications[0] || ''}
        </div>
        <div className={styles.row}>
          <h3>
            {intl.formatMessage({
              id: 'page.user_details.kyb.email',
              defaultMessage: 'Email',
            })}
          </h3>
          {userDetailsPageStore.kybVerifications.email}
        </div>
        <div className={styles.row}>
          <h3>
            {intl.formatMessage({
              id: 'page.user_details.kyb.phone',
              defaultMessage: 'Phone',
            })}
          </h3>
          {userDetailsPageStore.kybVerifications.phone}
        </div>
        <div className={styles.row}>
          <h3>
            {intl.formatMessage({
              id: 'page.user_details.kyb.meta',
              defaultMessage: 'Meta',
            })}
          </h3>

          <Button
            className={styles.apply_btn}
            variant='primary'
            onClick={onApplyMeta}
            disabled={!userDetailsPageStore.kybVerifications.meta}
          >
            {intl.formatMessage({
              id: 'page.user_details.kyb.show_json',
              defaultMessage: 'Show JSON',
            })}
          </Button>
        </div>
        <div className={styles.row}>
          <h3>
            {intl.formatMessage({
              id: 'page.user_details.kyb.status',
              defaultMessage: 'Status Info',
            })}
          </h3>

          <Button
            className={styles.apply_btn}
            variant='primary'
            onClick={onApplyStatus}
            disabled={
              !userDetailsPageStore.kybVerifications?.verifications?.[0]
            }
          >
            {intl.formatMessage({
              id: 'page.user_details.kyb.show_json',
              defaultMessage: 'Show JSON',
            })}
          </Button>
        </div>

        <div className={styles.container}>
          <Button
            disabled={!userDetailsPageStore.kybVerifications?.companyInfo}
            className={styles.apply_btn}
            variant='negative'
            onClick={() => {
              showEditCompanyInfoSidebar(
                userDetailsPageStore.kybVerifications?.companyInfo!
              );
            }}
          >
            {intl.formatMessage({
              id: 'page.user_details.kyb.edit',
              defaultMessage: 'Edit',
            })}
          </Button>
          <Button
            className={styles.apply_btn}
            variant='positive'
            onClick={onSync}
          >
            {intl.formatMessage({
              id: 'page.user_details.kyb.syncSubmit',
              defaultMessage: 'Sync/Submit',
            })}
          </Button>
        </div>
      </div>
      <div className={styles.col}>
        <div className={styles.row}>
          <h2 className={styles.title}>
            {intl.formatMessage({
              id: 'page.user_details.kyb.individuals',
              defaultMessage: 'Individuals',
            })}
          </h2>
        </div>
        <IndividualsTable
          onShowJSON={onApplyInd}
          onShowSidebar={showEditIndividualSidebar}
          collection={
            userDetailsPageStore.kybVerifications.companyInfo.individuals
          }
          expandedRow={ExpandedRow}
        />
      </div>
      <div className={styles.col}>
        <div className={styles.row}>
          <h2 className={styles.title}>
            {intl.formatMessage({
              id: 'page.user_details.kyb.documents',
              defaultMessage: 'Documents',
            })}
          </h2>
          <Button
            className={styles.apply_btn}
            variant='primary'
            onClick={() =>
              showUploadModal(
                userDetailsPageStore.kybVerifications?.verifications[0] || '',
                () => userDetailsPageStore.refresh()
              )
            }
          >
            {intl.formatMessage({
              id: 'page.user_details.kyb.add_document',
              defaultMessage: 'Add document',
            })}
          </Button>
        </div>
        <KybFilesTableAnt
          collection={userDetailsPageStore.kybFiles}
          loadFile={(file) => userDetailsPageStore.loadFile(file)}
          deleteFile={(file) =>
            showDeleteModal(file, () => userDetailsPageStore.refresh())
          }
        />
      </div>
    </div>
  );
});

export default UserDetailsKybTab;
