/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { BaseAdminUserVerification, VerificationStatus } from 'api/auth';

export class KycVerificationModel {
  constructor(dto: BaseAdminUserVerification) {
    this.id = dto.id ?? '';
    this.status = dto.status;
    this.type = dto.type;
    this.externalId = (dto as any).externalId;
  }

  id: string;
  status?: VerificationStatus;
  externalId?: string;
  type: string;
}
