import { OperationSelect } from '../../operation-select';
import styles from './index.module.css';
import { useIntl } from 'react-intl';
import Button from 'components/core/button';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import FormField from 'components/form/form-field';

import { FormValues, Step } from '..';
import { ExchangeInputs } from '../exchange-inputs';
import { useOperationStore } from '../../provider/OperationStoreProvider';
import FormSelect from 'components/form/form-select';

type StepTransferProps = {
  setStep: (step: Step) => void;
};

export const StepExchange = observer(({ setStep }: StepTransferProps) => {
  const intl = useIntl();
  const store = useOperationStore();

  const { handleSubmit, control, getValues, setValue } =
    useFormContext<FormValues>();

  const swapAccounts = () => {
    const { fromAccount, toAccount } = getValues();
    setValue('fromAccount', toAccount || '', { shouldValidate: true });
    setValue('toAccount', fromAccount || '', { shouldValidate: true });
    setValue('fromCurrency', '', { shouldValidate: true });
    setValue('toCurrency', '', { shouldValidate: true });

    store.exchange.feeData.reset();
  };

  const fromAccountsOptions = store.accounts.items.map((item) => ({
    id: item.id,
    label: item.id,
    value: item.id,
  }));

  const toAccountsOptions = fromAccountsOptions;

  return (
    <div className={styles.container}>
      <OperationSelect />
      <div className={styles.box}>
        <span className={styles.title}>
          {intl.formatMessage({
            id: 'sidebar.operation.accounts',
            defaultMessage: 'Accounts',
          })}
        </span>
        <div className={styles.card}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.from',
              defaultMessage: 'From',
            })}
          </span>
          <Controller
            rules={{
              required: intl.formatMessage({
                id: 'validation.field_required',
                defaultMessage: 'Field is required',
              }),
            }}
            control={control}
            name='fromAccount'
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormSelect
                  placeholder={intl.formatMessage({
                    id: 'sidebar.operation.account_id',
                    defaultMessage: 'Account ID',
                  })}
                  options={fromAccountsOptions}
                  onChange={(value) => onChange(value?.value as string)}
                  value={fromAccountsOptions.find(
                    (item) => item.value === value
                  )}
                />
              );
            }}
          />
          <Button
            variant='secondary'
            className={styles.button}
            onClick={swapAccounts}
          >
            {intl.formatMessage({
              id: 'sidebar.operation.swap_accounts',
              defaultMessage: 'Swap accounts',
            })}
          </Button>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'sidebar.operation.to',
              defaultMessage: 'To',
            })}
          </span>

          <Controller
            rules={{
              required: intl.formatMessage({
                id: 'validation.field_required',
                defaultMessage: 'Field is required',
              }),
            }}
            control={control}
            name='toAccount'
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormSelect
                placeholder={intl.formatMessage({
                  id: 'sidebar.operation.account_id',
                  defaultMessage: 'Account ID',
                })}
                options={toAccountsOptions}
                onChange={(value) => onChange(value?.value as string)}
                value={toAccountsOptions.find((item) => item.value === value)}
              />
            )}
          />
        </div>
      </div>

      <ExchangeInputs />

      <Controller
        control={control}
        name='note'
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField
            type='text_area'
            label={intl.formatMessage({
              id: 'sidebar.operation.note.title',
              defaultMessage: 'Note',
            })}
            placeholder={intl.formatMessage({
              id: 'sidebar.operation.note.placeholder',
              defaultMessage:
                'Specify a reason (The user will see your message)',
            })}
            value={value}
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Button
        variant='primary'
        disabled={
          store.exchange.feeData.loading || !store.exchange.feeData.value
        }
        onClick={handleSubmit(() => setStep('summary'))}
      >
        Continue
      </Button>
    </div>
  );
});
