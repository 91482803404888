/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import KycVerificationsTable from 'components/tables/kyc-verifications-table';

import { useParseCollectionQueryParams } from 'hooks/collection-query-params';
import { observer } from 'mobx-react-lite';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useEffect } from 'react';

const UserDetailsKycTab = observer(() => {
  const userDetailsPageStore = useUserDetailsPageStore();
  const { notificationStore, authApi } = useBaseStores();

  const { page, filter } = useParseCollectionQueryParams({
    collection: userDetailsPageStore.kycVerifications,
  });

  useEffect(() => {
    userDetailsPageStore.kycVerifications.fetchData(filter, page);
  }, [filter, page, userDetailsPageStore.kycVerifications]);

  const handleVarification = async (id: string) => {
    try {
      await authApi.admin.adminSyncVerification(id);
      notificationStore.notify({
        title: 'Successfully Synchronize',
        type: 'success',
      });
    } catch (error: any) {
      notificationStore.notify({
        title: error.message || 'Failed Synchronize',
        type: 'error',
      });
    }
  };

  return (
    <>
      <KycVerificationsTable
        collection={userDetailsPageStore.kycVerifications}
        onVerification={handleVarification}
      />
    </>
  );
});

export default UserDetailsKycTab;
