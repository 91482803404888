/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TableProps } from 'antd';
import { bigNumberToStr } from 'utils/bignumber';
import { SubscriptionsDetailsModel } from 'models/SubscriptionsDetailModel';
import TableSimple from 'components/core/table-simple';

import styles from './index.module.css';

type Props = {
  onRowClick: (value: SubscriptionsDetailsModel) => void;
  collection: SubscriptionsDetailsModel[];
};

const SubscriptionsTable = observer(({ collection, onRowClick }: Props) => {
  const intl = useIntl();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleRowSelectionChange = (
    selectedKeys: React.Key[],
    selectedRows: SubscriptionsDetailsModel[]
  ) => {
    setSelectedRowKeys(selectedKeys as string[]);

    if (selectedRows.length > 0) {
      onRowClick(selectedRows[0]);
    }
  };

  const handleRowClick = (record: SubscriptionsDetailsModel) => {
    if (record.id) {
      const newSelectedRowKeys = [record.id];
      setSelectedRowKeys(newSelectedRowKeys);
      onRowClick(record);
    }
  };

  const columns: TableProps<SubscriptionsDetailsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.subscriptionsTypes.col.id',
        defaultMessage: 'Id',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <span>{id}</span>,
      width: 50,
    },
    {
      title: intl.formatMessage({
        id: 'table.invoices.col.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => (
        <span>
          {bigNumberToStr(record.amount)} {record.currency}
        </span>
      ),
      width: 30,
    },
    {
      title: intl.formatMessage({
        id: 'table.subscriptionsTypes.col.initialPaymentAmount',
        defaultMessage: 'Initial Payment Amount',
      }),
      dataIndex: 'initialPaymentAmount',
      key: 'initialPaymentAmount',
      render: (initialPaymentAmount) => (
        <span>{bigNumberToStr(initialPaymentAmount)}</span>
      ),
      width: 30,
    },

    {
      title: intl.formatMessage({
        id: 'table.subscriptionsTypes.col.name',
        defaultMessage: 'Name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <span>{name}</span>,
      width: 30,
    },

    {
      title: intl.formatMessage({
        id: 'table.subscriptionsTypes.col.description',
        defaultMessage: 'Description',
      }),
      dataIndex: 'description',
      key: 'description',
      render: (description) => <span>{description}</span>,
      width: 30,
    },

    {
      title: intl.formatMessage({
        id: 'table.subscriptionsTypes.col.partnerName',
        defaultMessage: 'Partner Name',
      }),
      dataIndex: 'partnerName',
      key: 'partnerName',
      render: (partnerName) => <span>{partnerName}</span>,
      width: 30,
    },
  ];

  return (
    <TableSimple
      className={styles.table}
      collection={collection}
      columns={columns}
      rowKey='id'
      scroll={{ y: '600px' }}
      rowSelection={{
        type: 'radio',
        columnWidth: '15px',
        selectedRowKeys,
        onChange: handleRowSelectionChange,
      }}
      onRow={(record) => ({
        onClick: () => handleRowClick(record),
      })}
    />
  );
});

export default SubscriptionsTable;
