type DownloadProps = {
  data: string;
  type: string;
  filename: string;
};

export const dowload = ({ data, type, filename }: DownloadProps) => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename ?? 'file');

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
};
