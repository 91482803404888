/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useBaseStores } from 'providers/BaseStoresProvider';
import { UserDetailsPageStore } from '../store/UserDetailsPageStore';

const storeContext = createContext<UserDetailsPageStore>(
  {} as UserDetailsPageStore
);

type Props = {
  userId: string;
  children?: React.ReactNode;
};
export const ProvideUserDetailsPageStore = observer(
  ({ children, userId }: Props) => {
    const {
      partnerStore,
      authApi,
      walletApi,
      cardHolderApi,
      acquiringApi,
      notificationStore,
      loansApi,
      currencyStore,
      questsApi,
    } = useBaseStores();

    const store = useLocalObservable(
      () =>
        new UserDetailsPageStore(
          authApi,
          walletApi,
          loansApi,
          cardHolderApi,
          acquiringApi,
          notificationStore,
          currencyStore,
          questsApi
        )
    );

    useEffect(() => {
      if (!partnerStore.currentPartner?.id) {
        return;
      }

      store.init(userId, partnerStore.currentPartner.id);
    }, [partnerStore.currentPartner?.id, store, userId]);

    return (
      <storeContext.Provider value={store}>{children}</storeContext.Provider>
    );
  }
);

export const useUserDetailsPageStore = () => {
  return useContext(storeContext);
};
