/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import BackButton from 'components/elements/back-button';
import UserDetailsBasicInfo from './basic-info';
import Tabs from 'components/core/tabs';
import UserDetailsSessionsTab from './tabs/sessions';
import UserDetailsKycTab from './tabs/kyc';
import UserDetailsKybTab from './tabs/kyb';
import UserDetailsAccountsTab from './tabs/accounts';
import UserDetailsLoansTab from './tabs/loans';
import UserDetailsWithdrawalsTab from './tabs/withdrawals';
import UserDetailsDepositsTab from './tabs/deposits';
import UserDetailsTransactionsTab from './tabs/transactions';
import UserDetailsCardsTab from './tabs/cards';
import UserDetailsInvoicesTab from './tabs/invoices';
import UserDetailsSubscriptionsTab from './tabs/subscriptions';

import { ROUTES } from 'routes';
import { observer } from 'mobx-react-lite';
import {
  ProvideUserDetailsPageStore,
  useUserDetailsPageStore,
} from './provider/StoreProvider';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { UserDetailsNotesTab } from './tabs/notes';
import { ProvideUserNotesPageStore } from './provider/NotesStoreProvider';
import UserDetailsAccountTab from './tabs/account';
import { ProvideUploadStore } from './tabs/kyb/provider/UploadStoreProvider';
import UserDetailsQuestsTab from './tabs/quests';

const UserDetailsPageWithProvider = observer(() => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const userDetailsPageStore = useUserDetailsPageStore();

  const userId = useMemo(
    () => userDetailsPageStore.details?.id ?? '',
    [userDetailsPageStore.details?.id]
  );

  const tabsData = useMemo(
    () => [
      {
        id: 'notes',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.notes',
          defaultMessage: 'Notes',
        }),
        navLink: ROUTES.userDetails.notes.getFullPath(userId),
      },
      {
        id: 'sessions',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.sessions',
          defaultMessage: 'Sessions',
        }),
        navLink: ROUTES.userDetails.sessions.getFullPath(userId),
      },
      {
        id: 'kyc',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.kyc',
          defaultMessage: 'KYC',
        }),
        navLink: ROUTES.userDetails.kyc.getFullPath(userId),
      },
      {
        id: 'kyb',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.kyb',
          defaultMessage: 'KYB',
        }),
        navLink: ROUTES.userDetails.kyb.getFullPath(userId),
      },
      {
        id: 'lones',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.loans',
          defaultMessage: 'Loans',
        }),
        navLink: ROUTES.userDetails.loans.getFullPath(userId),
      },
      {
        id: 'accounts',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.accounts',
          defaultMessage: 'Accounts',
        }),
        end: false,
        navLink: ROUTES.userDetails.accounts.getFullPath(userId),
      },
      {
        id: 'withdrawals',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.withdrawals',
          defaultMessage: 'Withdrawals',
        }),
        navLink: ROUTES.userDetails.withdrawals.getFullPath(userId),
      },
      {
        id: 'deposits',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.deposits',
          defaultMessage: 'Deposits',
        }),
        navLink: ROUTES.userDetails.deposits.getFullPath(userId),
      },
      {
        id: 'transactions',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.transactions',
          defaultMessage: 'Transactions',
        }),
        navLink: ROUTES.userDetails.transactions.getFullPath(userId),
      },
      {
        id: 'cards',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.cards',
          defaultMessage: 'Cards',
        }),
        navLink: ROUTES.userDetails.cards.getFullPath(userId),
      },
      {
        id: 'invoices',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.invoices',
          defaultMessage: 'Invoices',
        }),
        navLink: ROUTES.userDetails.invoices.getFullPath(userId),
      },
      {
        id: 'subscriptions',
        text: intl.formatMessage({
          id: 'page.user_details.tabs.subscriptions',
          defaultMessage: 'Subscriptions',
        }),
        navLink: ROUTES.userDetails.subscriptions.getFullPath(userId),
      },
      // {
      //   id: 'quests',
      //   text: intl.formatMessage({
      //     id: 'page.user_details.tabs.quests',
      //     defaultMessage: 'Quests',
      //   }),
      //   navLink: ROUTES.userDetails.quests.getFullPath(userId),
      // },
    ],
    [intl, userId]
  );

  useEffect(() => {
    layoutStore.setNavbarTitle(
      intl.formatMessage({
        id: 'page.user_details.title',
        defaultMessage: 'User',
      })
    );

    return () => layoutStore.setNavbarTitle(null);
  }, [intl, layoutStore]);

  if (!userDetailsPageStore.details) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <BackButton fallbackUrl={ROUTES.users.fullPath} />

        <Tabs data={tabsData} />
      </div>

      <div className={styles.body}>
        <div className={styles.basic_info}>
          <UserDetailsBasicInfo />
        </div>

        <div className={styles.tab_container}>
          <Routes>
            <Route element={<UserDetailsNotesTab />} index />
            <Route
              path={ROUTES.userDetails.sessions.path}
              element={<UserDetailsSessionsTab />}
            />
            <Route
              path={ROUTES.userDetails.kyc.path}
              element={<UserDetailsKycTab />}
            />
            <Route
              path={ROUTES.userDetails.kyb.path}
              element={
                <ProvideUploadStore>
                  <UserDetailsKybTab />
                </ProvideUploadStore>
              }
            />
            <Route
              path={ROUTES.userDetails.accounts.path}
              element={<UserDetailsAccountsTab />}
            />
            <Route
              path={ROUTES.userDetails.accountDetails.path}
              element={<UserDetailsAccountTab />}
            />
            <Route
              path={ROUTES.userDetails.loans.path}
              element={<UserDetailsLoansTab />}
            />
            <Route
              path={ROUTES.userDetails.withdrawals.path}
              element={<UserDetailsWithdrawalsTab />}
            />
            <Route
              path={ROUTES.userDetails.deposits.path}
              element={<UserDetailsDepositsTab />}
            />
            <Route
              path={ROUTES.userDetails.transactions.path}
              element={<UserDetailsTransactionsTab />}
            />
            <Route
              path={ROUTES.userDetails.cards.path}
              element={<UserDetailsCardsTab />}
            />
            <Route
              path={ROUTES.userDetails.invoices.path}
              element={<UserDetailsInvoicesTab />}
            />
            <Route
              path={ROUTES.userDetails.subscriptions.path}
              element={<UserDetailsSubscriptionsTab />}
            />
            <Route
              path={ROUTES.userDetails.quests.path}
              element={<UserDetailsQuestsTab />}
            />
            <Route
              path='*'
              element={
                <Navigate to={ROUTES.userDetails.getFullPath(userId)} replace />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
});

const UserDetailsPage = () => {
  const { userId } = useParams();

  if (!userId) {
    return null;
  }

  return (
    <ProvideUserDetailsPageStore userId={userId}>
      <ProvideUserNotesPageStore userId={userId}>
        <UserDetailsPageWithProvider />
      </ProvideUserNotesPageStore>
    </ProvideUserDetailsPageStore>
  );
};

export default UserDetailsPage;
