/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi, WalletApi } from 'api';
import {
  AtmWithdraw,
  Balance,
  BankTransferWithdraw,
  BaseWithdraw,
  CounterPartyWithdraw,
  CryptoWithdraw,
  OperationType,
  PaySpotWithdraw,
} from 'api/wallet';
import { makeAutoObservable, runInAction } from 'mobx';
import { AccountUserModel } from 'models/AccountUserModel';
import { DataSync } from 'stores/DataStore';
import { NotificationStore } from 'stores/NotificationStore';

export class OperationStore {
  constructor(
    private authApi: AuthApi,
    private walletApi: WalletApi,
    private notificationStore: NotificationStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public loading = false;
  public type: OperationType = OperationType.TRANSFER;
  public partnerId = 0;

  private _userId = '';

  changeType(value: OperationType) {
    this.type = value;
  }

  public accountBalances = {
    error: '',
    loading: false,
    from: [] as Balance[],
    to: [] as Balance[],
    fetch: async (accountId: string, key: 'from' | 'to' = 'from') => {
      if (!accountId) {
        runInAction(() => {
          this.accountBalances[key] = [];
        });
        return;
      }
      try {
        runInAction(() => {
          this.accountBalances.loading = true;
        });
        const result = await this.walletApi.admin.adminGetAccountBalance(
          accountId,
          undefined,
          {
            headers: { PartnerId: this.partnerId },
          }
        );
        runInAction(() => {
          this.accountBalances.loading = true;
          this.accountBalances[key] = result.data;
        });
      } catch (error) {
        runInAction(() => {
          this.accountBalances.loading = false;
        });
      }
    },
  };

  private _exchange = {
    loading: false,
    feeData: new DataSync(
      this.walletApi.admin.adminCreateDirectExchange.bind(this.walletApi)
    ),
    create: async () => {
      try {
        runInAction(() => {
          this._exchange.loading = true;
        });
        const { toAmount, ...data } = this.exchange.feeData.value?.data!;
        await this.walletApi.admin.adminCreateDirectExchange(
          data,
          {
            dryRun: false,
          },
          {
            headers: { PartnerId: this.partnerId },
          }
        );
        this.notificationStore.notify({
          title: 'Successfully Transaction',
          type: 'success',
        });
        runInAction(() => {
          this._exchange.loading = true;
        });

        return true;
      } catch (error: any) {
        this.notificationStore.notify({
          title: error.message || 'Failed Transaction',
          type: 'success',
        });
        runInAction(() => {
          this._withdraw.loading = false;
        });

        return false;
      }
    },
  };

  private _withdraw = {
    loading: false,
    feeData: new DataSync(
      this.walletApi.admin.adminCreateWithdraw.bind(this.walletApi)
    ),
    create: async (
      data:
        | AtmWithdraw
        | BankTransferWithdraw
        | CryptoWithdraw
        | PaySpotWithdraw
        | CounterPartyWithdraw
        | BaseWithdraw
    ) => {
      try {
        runInAction(() => {
          this._withdraw.loading = true;
        });
        await this.walletApi.admin.adminCreateWithdraw(
          data as any,
          {
            dryRun: false,
            autoConversion: true,
          },
          {
            headers: { PartnerId: this.partnerId },
          }
        );
        this.notificationStore.notify({
          title: 'Successfully Transaction',
          type: 'success',
        });
        runInAction(() => {
          this._withdraw.loading = true;
        });
      } catch (error: any) {
        this.notificationStore.notify({
          title: error.message || 'Failed Transaction',
          type: 'success',
        });
        runInAction(() => {
          this._withdraw.loading = false;
        });
      }
    },
  };

  private _transfer = {
    loading: false,
    create: async (data: any) => {
      try {
        runInAction(() => {
          this._transfer.loading = true;
        });
        await this.walletApi.admin.adminCreateTransfer({
          amount: data.amount,
          fromAccount: data.fromAccount,
          toAccount: data.toAccount,
          currency: data.currency,
          type: 'ORIGINAL',
        });
        runInAction(() => {
          this._transfer.loading = true;
        });

        this.notificationStore.notify({
          title: 'Successfully Transaction',
          type: 'success',
        });

        return true;
      } catch (error: any) {
        this.notificationStore.notify({
          title: error.message || 'Failed Transaction',
          type: 'error',
        });
        runInAction(() => {
          this._transfer.loading = false;
        });

        return false;
      }
    },
  };

  private _accounts = {
    items: [] as AccountUserModel[],
    fetchFn: async (userId: string) => {
      const result = await this.walletApi.admin.adminListAccountByUser(
        {
          userId: userId,
        },
        { headers: { PartnerId: this.partnerId } }
      );

      this._accounts.items = result.data.map((x) => new AccountUserModel(x));
    },
  };

  get withdraw() {
    return this._withdraw;
  }

  get exchange() {
    return this._exchange;
  }

  get transfer() {
    return this._transfer;
  }

  get accounts() {
    return this._accounts;
  }

  async init(props: {
    partnerId: number;
    userId: string;
    type: OperationType;
  }) {
    const { partnerId, userId, type } = props;
    runInAction(() => {
      this.partnerId = partnerId;
      this._userId = userId;
      this.type = type;
    });

    this.accounts.fetchFn(userId);
  }
}
