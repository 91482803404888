/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BaseTransactionSpecificDataDto {
  type: string;
}

export interface BasePII {
  type: string;
}

export interface BasePIIDto {
  type: string;
}

export interface AccountBalance {
  /**
   * Total balance
   * @format number
   * @example "100.0"
   */
  totalBalance?: string;
  /**
   * Available balance.
   * @format number
   * @example "100.0"
   */
  availableBalance?: string;
  /**
   * Hold balance.
   * @format number
   * @example "100.0"
   */
  holdBalance?: string;
}

/** Physical card activation. */
export interface ActivateCard {
  /** Activation code. */
  activationCode: string;
}

/** Card data. */
export interface AdminCard {
  /**
   * Card id.
   * @format uuid
   */
  id: string;
  /** User id. */
  userId: string;
  /** Account id. */
  accountId?: string;
  /** Additional card info. */
  additionalCardInfo?: object;
  status?: CardStatus;
  /**
   * Card balance.
   * @format number
   */
  balance?: string;
  /** Card last 4 digits. */
  last4?: string;
  /** Cardholder name. */
  cardholderName?: string;
  /** Secondary cardholder name. */
  secondaryCardholderName?: string;
  /** Card expire date. */
  expireDate?: string;
  cardType?: CardType;
}

export interface ApiError {
  key?: string;
  description: string;
}

export interface ApiErrorDto {
  key?: string;
  description: string;
}

export interface ApiErrorResponse {
  status: HttpStatus;
  message: string;
  traceId: string;
  errors?: ApiError[];
}

export interface ApiErrorResponseDto {
  status: HttpStatusDto;
  message: string;
  traceId: string;
  errors?: ApiErrorDto[];
}

export interface AuthorizationCallbackRequestDto {
  id: string;
  card_id: string;
  amount: number;
  /**
   * Currency code in ISO 4217 format, in uppercase
   * @example "EUR"
   */
  currency: string;
  merchant_amount?: number;
  /**
   * Currency code in ISO 4217 format, in uppercase
   * @example "EUR"
   */
  merchant_currency?: string;
  merchant_data?: MerchantDataDto;
  authorization_method?: string;
}

export interface AuthorizationCallbackResponseDto {
  approved: boolean;
  amount?: number;
  additionalData?: Record<string, string>;
}

export interface Balance {
  amount?: number;
  currency?: string;
}

export interface BalanceDto {
  /**
   * User account
   * @format uuid
   */
  account: string;
  /**
   * Balance
   * @example 9.87
   */
  balance: number;
  /**
   * To currency short name
   * @example "EPI"
   */
  currency: string;
  /**
   * Last date time of balance updated
   * @format date-time
   */
  lastUpdated?: string;
}

/** Business Personally Identifiable Information (PII) data. */
export type BusinessPII = BasePIIDto & {
  /** Full name. */
  fullName: string;
  /**
   * Entity type. Awarded values: `Person`, `Company`
   * @example "Company"
   */
  entityType: string;
  /** User address */
  registeredAddress: UserAddress;
  /** Business name. */
  businessName: string;
  /** Business registration number. */
  businessRegistrationNumber: string;
  /** User address */
  businessOperationAddress: UserAddress;
  /** User Personally Identifiable Information (PII) data. */
  contactEntityPii?: UserPII;
} & BasePII & {
    fullName: string;
    entityType: string;
    /** User address */
    registeredAddress: UserAddress;
    businessName: string;
    businessRegistrationNumber: string;
    /** User address */
    businessOperationAddress: UserAddress;
    /** User Personally Identifiable Information (PII) data. */
    contactEntityPii?: UserPII;
  };

/** Card data. */
export interface Card {
  /**
   * Card id.
   * @format uuid
   */
  id: string;
  /** User id. */
  userId: string;
  /** Account id. */
  accountId?: string;
  /** Additional card info. */
  additionalCardInfo?: JsonNode;
  status?: CardStatusDto;
  /** Card design data */
  cardDesign?: CardDesign;
}

/** Card design data */
export interface CardDesign {
  /** @format uuid */
  id?: string;
  name?: string;
  description?: string;
  image?: string;
  thumbnail?: string;
}

/** Card details. */
export interface CardDetails {
  /** Last 4 digits of the card number. */
  last4: string;
  /** Cardname. */
  cardName?: string;
  /** Secondary card name (additional line on card). */
  secondaryCardName?: string;
  /** Card design. */
  cardDesignId?: string;
}

/** Card email update request */
export interface CardEmailUpdate {
  /** Phone number. */
  email?: string;
}

/** Object represents cap or current state of limits. */
export interface CardLimit {
  /**
   * Transaction.
   * @example 1000
   */
  transaction?: number;
  /**
   * Daily.
   * @example 1000
   */
  daily?: number;
  /**
   * Weekly.
   * @example 1000
   */
  weekly?: number;
  /**
   * Monthly.
   * @example 1000
   */
  monthly?: number;
  /**
   * Yearly.
   * @example 1000
   */
  yearly?: number;
  /**
   * All time.
   * @example 1000
   */
  allTime?: number;
}

/** Card limits. `cap` is limit, `current` is current state. If object is null - than it is not supported by provider */
export interface CardLimits {
  /** Object represents cap or current state of limits. */
  cap?: CardLimit;
  /** Object represents cap or current state of limits. */
  current?: CardLimit;
}

/** Card offer data. */
export interface CardOffer {
  /** @format uuid */
  id?: string;
  provider?: ProviderDto;
  allowedCardDesigns?: CardDesign[];
  allowUserPrimaryCardname?: boolean;
  allowUserSecondaryCardname?: boolean;
  allowVirtualCards?: boolean;
  allowPhysicalCards?: boolean;
  firstVirtualPriceCurrency?: string;
  firstVirtualPriceAmount?: number;
  virtualPriceCurrency?: string;
  virtualPriceAmount?: number;
  firstPhysicalPriceCurrency?: string;
  firstPhysicalPriceAmount?: number;
  physicalPriceCurrency?: string;
  physicalPriceAmount?: number;
}

/** Card phone update request */
export interface CardPhoneUpdate {
  /** Phone number. */
  phone?: string;
}

/** Card prefund request */
export interface CardPrefund {
  /** Adjustment amount to prefund */
  amount?: number;
  /** Currency code */
  currency?: string;
}

/** Card request data. */
export interface CardRequest {
  /** @format uuid */
  id?: string;
  provider?: ProviderDto;
  accountId?: string;
  status?: CardRequestStatusDto;
  /** @format date-time */
  createdDate?: string;
  cardName?: string;
  secondaryCardName?: string;
  cardType?: CardTypeDto;
  pii?: CardRequestPiiDto;
  /** Card design data */
  cardDesign?: CardDesign;
  /** User address */
  deliveryAddress?: UserAddress;
  invoiceId?: string;
}

export type CardRequestPiiDto = object;

export enum CardRequestStatusDto {
  INIT = 'INIT',
  INVOICE_PROCESSED = 'INVOICE_PROCESSED',
  PENDING = 'PENDING',
  ISSUED = 'ISSUED',
  REJECTED = 'REJECTED',
  INVOICE_FAILED = 'INVOICE_FAILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

/** Card sensitive details. */
export type CardSensitiveDetails = BaseCardSensitiveDetails &
  (
    | BaseCardSensitiveDetailsTypeMapping<'revealed', CardSensitiveDetailsRevealed>
    | BaseCardSensitiveDetailsTypeMapping<'encrypted', CardSensitiveDetailsEncrypted>
    | BaseCardSensitiveDetailsTypeMapping<'iframe', CardSensitiveDetailsIframe>
  );

export interface CardSensitiveDetailsDto {
  type: string;
}

export interface CardSensitiveDetailsEncrypted {
  encryptedDetails?: string;
}

export interface CardSensitiveDetailsIframe {
  iframe?: string;
}

export interface CardSensitiveDetailsRevealed {
  cardNumber?: string;
  cardName?: string;
  secondaryCardName?: string;
  expDate?: string;
  cvc?: string;
}

export enum CardStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  ISSUED = 'ISSUED',
  ACTIVE = 'ACTIVE',
  FROZEN = 'FROZEN',
  LOST = 'LOST',
  STOLEN = 'STOLEN',
  INACTIVE = 'INACTIVE',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
}

export enum CardStatusDto {
  INIT = 'INIT',
  PENDING = 'PENDING',
  ISSUED = 'ISSUED',
  ACTIVE = 'ACTIVE',
  FROZEN = 'FROZEN',
  LOST = 'LOST',
  STOLEN = 'STOLEN',
  INACTIVE = 'INACTIVE',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export enum CardType {
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
}

export enum CardTypeDto {
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

/** Change card type data. */
export interface ChangeCardType {
  cardType: CardTypeDto;
  /** User address */
  address: UserAddress;
}

/** Create card data. */
export interface CreateCard {
  provider: ProviderDto;
  card_type: CardTypeDto;
  /** (Optional) External account id. */
  account_id?: string;
  /**
   * (Optional) Preferred card name. If not provided, the cardholder name will be used.
   * @pattern ^[a-zA-Z0-9 \-_]{1,27}$
   */
  preferred_cardname?: string;
  /**
   * (Optional) Secondary card name (second line).
   * @pattern ^[a-zA-Z0-9 \-_]{1,27}$
   */
  secondary_cardname?: string;
  /** (Optional) Encrypted PII data. Required for several card providers. */
  encrypted_pii?: string;
  pii?: BusinessPII | UserPII;
  /** User address */
  delivery_address?: UserAddress;
  /** (Optional) Card design id. */
  card_design_id?: string;
}

/** Card request data. */
export interface CreateCardRequest {
  /**
   * Card offer
   * @format uuid
   */
  card_offer_id: string;
  card_type: CardTypeDto;
  /** (Optional) External account id. */
  account_id?: string;
  /**
   * (Optional) Preferred card name. If not provided, the cardholder name will be used.
   * @pattern ^[a-zA-Z0-9 \-_]{1,27}$
   */
  preferred_cardname?: string;
  /**
   * (Optional) Secondary card name (second line).
   * @pattern ^[a-zA-Z0-9 \-_]{1,27}$
   */
  secondary_cardname?: string;
  /** (Optional) Encrypted PII data. Required for several card providers. */
  encrypted_pii?: string;
  pii?: BusinessPII | UserPII;
  /** User address */
  delivery_address?: UserAddress;
  /**
   * (Optional) Card design id.
   * @format uuid
   */
  card_design_id?: string;
  provider: ProviderDto;
}

/** Finci transaction specific data. */
export type FinciTransactionSpecificData = BaseTransactionSpecificDataDto & {
  thirdPartyInfo?: string;
};

export enum HttpStatus {
  Value100CONTINUE = '100 CONTINUE',
  Value101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  Value102PROCESSING = '102 PROCESSING',
  Value103EARLYHINTS = '103 EARLY_HINTS',
  Value103CHECKPOINT = '103 CHECKPOINT',
  Value200OK = '200 OK',
  Value201CREATED = '201 CREATED',
  Value202ACCEPTED = '202 ACCEPTED',
  Value203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  Value204NOCONTENT = '204 NO_CONTENT',
  Value205RESETCONTENT = '205 RESET_CONTENT',
  Value206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  Value207MULTISTATUS = '207 MULTI_STATUS',
  Value208ALREADYREPORTED = '208 ALREADY_REPORTED',
  Value226IMUSED = '226 IM_USED',
  Value300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  Value301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  Value302FOUND = '302 FOUND',
  Value302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  Value303SEEOTHER = '303 SEE_OTHER',
  Value304NOTMODIFIED = '304 NOT_MODIFIED',
  Value305USEPROXY = '305 USE_PROXY',
  Value307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  Value308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  Value400BADREQUEST = '400 BAD_REQUEST',
  Value401UNAUTHORIZED = '401 UNAUTHORIZED',
  Value402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  Value403FORBIDDEN = '403 FORBIDDEN',
  Value404NOTFOUND = '404 NOT_FOUND',
  Value405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  Value406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  Value407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  Value408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  Value409CONFLICT = '409 CONFLICT',
  Value410GONE = '410 GONE',
  Value411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  Value412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  Value413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  Value413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  Value414URITOOLONG = '414 URI_TOO_LONG',
  Value414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  Value415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  Value416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  Value417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  Value418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  Value419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  Value420METHODFAILURE = '420 METHOD_FAILURE',
  Value421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  Value422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  Value423LOCKED = '423 LOCKED',
  Value424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  Value425TOOEARLY = '425 TOO_EARLY',
  Value426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  Value428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  Value429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  Value431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  Value451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  Value500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  Value501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  Value502BADGATEWAY = '502 BAD_GATEWAY',
  Value503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  Value504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  Value505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  Value506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  Value507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  Value508LOOPDETECTED = '508 LOOP_DETECTED',
  Value509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  Value510NOTEXTENDED = '510 NOT_EXTENDED',
  Value511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
}

export enum HttpStatusDto {
  Value100CONTINUE = '100 CONTINUE',
  Value101SWITCHINGPROTOCOLS = '101 SWITCHING_PROTOCOLS',
  Value102PROCESSING = '102 PROCESSING',
  Value103EARLYHINTS = '103 EARLY_HINTS',
  Value103CHECKPOINT = '103 CHECKPOINT',
  Value200OK = '200 OK',
  Value201CREATED = '201 CREATED',
  Value202ACCEPTED = '202 ACCEPTED',
  Value203NONAUTHORITATIVEINFORMATION = '203 NON_AUTHORITATIVE_INFORMATION',
  Value204NOCONTENT = '204 NO_CONTENT',
  Value205RESETCONTENT = '205 RESET_CONTENT',
  Value206PARTIALCONTENT = '206 PARTIAL_CONTENT',
  Value207MULTISTATUS = '207 MULTI_STATUS',
  Value208ALREADYREPORTED = '208 ALREADY_REPORTED',
  Value226IMUSED = '226 IM_USED',
  Value300MULTIPLECHOICES = '300 MULTIPLE_CHOICES',
  Value301MOVEDPERMANENTLY = '301 MOVED_PERMANENTLY',
  Value302FOUND = '302 FOUND',
  Value302MOVEDTEMPORARILY = '302 MOVED_TEMPORARILY',
  Value303SEEOTHER = '303 SEE_OTHER',
  Value304NOTMODIFIED = '304 NOT_MODIFIED',
  Value305USEPROXY = '305 USE_PROXY',
  Value307TEMPORARYREDIRECT = '307 TEMPORARY_REDIRECT',
  Value308PERMANENTREDIRECT = '308 PERMANENT_REDIRECT',
  Value400BADREQUEST = '400 BAD_REQUEST',
  Value401UNAUTHORIZED = '401 UNAUTHORIZED',
  Value402PAYMENTREQUIRED = '402 PAYMENT_REQUIRED',
  Value403FORBIDDEN = '403 FORBIDDEN',
  Value404NOTFOUND = '404 NOT_FOUND',
  Value405METHODNOTALLOWED = '405 METHOD_NOT_ALLOWED',
  Value406NOTACCEPTABLE = '406 NOT_ACCEPTABLE',
  Value407PROXYAUTHENTICATIONREQUIRED = '407 PROXY_AUTHENTICATION_REQUIRED',
  Value408REQUESTTIMEOUT = '408 REQUEST_TIMEOUT',
  Value409CONFLICT = '409 CONFLICT',
  Value410GONE = '410 GONE',
  Value411LENGTHREQUIRED = '411 LENGTH_REQUIRED',
  Value412PRECONDITIONFAILED = '412 PRECONDITION_FAILED',
  Value413PAYLOADTOOLARGE = '413 PAYLOAD_TOO_LARGE',
  Value413REQUESTENTITYTOOLARGE = '413 REQUEST_ENTITY_TOO_LARGE',
  Value414URITOOLONG = '414 URI_TOO_LONG',
  Value414REQUESTURITOOLONG = '414 REQUEST_URI_TOO_LONG',
  Value415UNSUPPORTEDMEDIATYPE = '415 UNSUPPORTED_MEDIA_TYPE',
  Value416REQUESTEDRANGENOTSATISFIABLE = '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  Value417EXPECTATIONFAILED = '417 EXPECTATION_FAILED',
  Value418IAMATEAPOT = '418 I_AM_A_TEAPOT',
  Value419INSUFFICIENTSPACEONRESOURCE = '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  Value420METHODFAILURE = '420 METHOD_FAILURE',
  Value421DESTINATIONLOCKED = '421 DESTINATION_LOCKED',
  Value422UNPROCESSABLEENTITY = '422 UNPROCESSABLE_ENTITY',
  Value423LOCKED = '423 LOCKED',
  Value424FAILEDDEPENDENCY = '424 FAILED_DEPENDENCY',
  Value425TOOEARLY = '425 TOO_EARLY',
  Value426UPGRADEREQUIRED = '426 UPGRADE_REQUIRED',
  Value428PRECONDITIONREQUIRED = '428 PRECONDITION_REQUIRED',
  Value429TOOMANYREQUESTS = '429 TOO_MANY_REQUESTS',
  Value431REQUESTHEADERFIELDSTOOLARGE = '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  Value451UNAVAILABLEFORLEGALREASONS = '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  Value500INTERNALSERVERERROR = '500 INTERNAL_SERVER_ERROR',
  Value501NOTIMPLEMENTED = '501 NOT_IMPLEMENTED',
  Value502BADGATEWAY = '502 BAD_GATEWAY',
  Value503SERVICEUNAVAILABLE = '503 SERVICE_UNAVAILABLE',
  Value504GATEWAYTIMEOUT = '504 GATEWAY_TIMEOUT',
  Value505HTTPVERSIONNOTSUPPORTED = '505 HTTP_VERSION_NOT_SUPPORTED',
  Value506VARIANTALSONEGOTIATES = '506 VARIANT_ALSO_NEGOTIATES',
  Value507INSUFFICIENTSTORAGE = '507 INSUFFICIENT_STORAGE',
  Value508LOOPDETECTED = '508 LOOP_DETECTED',
  Value509BANDWIDTHLIMITEXCEEDED = '509 BANDWIDTH_LIMIT_EXCEEDED',
  Value510NOTEXTENDED = '510 NOT_EXTENDED',
  Value511NETWORKAUTHENTICATIONREQUIRED = '511 NETWORK_AUTHENTICATION_REQUIRED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

export enum InvoiceStatusDto {
  INIT = 'INIT',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

/** Additional card info. */
export type JsonNode = object;

export interface MerchantDataDto {
  mcc_category?: string;
  mcc_code?: string;
  city?: string;
  country?: string;
  name?: string;
  network_id?: string;
  postal_code?: string;
  state?: string;
}

/** List of payments */
export type OriginalInvoiceDtoAllOfPaymentsDto = object;

export interface OriginalInvoiceDtoDto {
  /**
   * field for client_id in external system
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  externalClientId?: string;
  /**
   * invoice id
   * @example "8b3d1cdc-cd79-42ae-8040-7404c35a3026"
   */
  id?: string;
  /**
   * Amount required to complete invoice
   * @example 0
   */
  amount: number;
  /**
   * Currency slug in internal format
   * @example "EUR"
   */
  currency: string;
  /**
   * Date of the payment of this invoice
   * @format int64
   */
  paymentDate?: number;
  status?: InvoiceStatusDto;
  /** List of payments */
  payments?: OriginalInvoiceDtoAllOfPaymentsDto[];
  /** Discount code, that was applied to this invoice */
  discountCode?: string;
}

export interface PageableResponseAdminCard {
  data: AdminCard[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseTransactionDto {
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
  data: Transaction[];
}

/** PFS transaction specific data. */
export type PfsTransactionSpecificData = BaseTransactionSpecificDataDto & {
  mcc?: string;
  mti?: string;
  arn?: string;
  stn?: string;
  termId?: string;
  authNum?: string;
  recType?: string;
  availableBalance?: string;
  ledgerBalance?: string;
  issuerFee?: string;
  clientId?: string;
  termNameLocation?: string;
  termOwner?: string;
  termCity?: string;
  termState?: string;
  termCountry?: string;
  surcharge?: string;
  rspCode?: string;
  origHoldAmt?: string;
  termCurrency?: string;
  origTransAmt?: string;
  atmFee?: string;
};

export interface PinReset {
  /** Secret question. */
  secretQuestion?: string;
  /** Secret question answer. */
  secretQuestionAnswer?: string;
  /** PIN. */
  pin: string;
}

export interface PinResetDto {
  /** Secret question. */
  secretQuestion?: string;
  /** Secret question answer. */
  secretQuestionAnswer?: string;
  /** PIN. */
  pin: string;
}

export type ProcessWebhook200ResponseDto = object;

export enum Provider {
  PFSCARD = 'PFSCARD',
  FINCI = 'FINCI',
  STRIPE = 'STRIPE',
  REAP = 'REAP',
}

export enum ProviderDto {
  PFSCARD = 'PFSCARD',
  FINCI = 'FINCI',
  STRIPE = 'STRIPE',
  REAP = 'REAP',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

/** Reap transaction specific data. */
export type ReapTransactionSpecificData = BaseTransactionSpecificDataDto & {
  txAmount?: number;
  txCurrency?: string;
  billAmount?: number;
  billCurrency?: string;
  conversionRate?: string;
  channel?: string;
  posEntryModeCode?: string;
  posEntryModeDescription?: string;
  atmFees?: number;
  fxFees?: number;
  wallet?: string;
  status?: string;
  category?: string;
  isCredit?: boolean;
  declineReasonResponseCode?: string;
  declineReasonDescription?: string;
};

/** Top-up information. */
export type TopUpInformation = BaseTopUpInformation &
  (
    | BaseTopUpInformationTypeMapping<'crypto', TopUpInformationCrypto>
    | BaseTopUpInformationTypeMapping<'iban', TopUpInformationIban>
  );

export interface TopUpInformationCrypto {
  /**
   * Type of top up information.
   * @example "crypto"
   */
  type: string;
  address?: string;
  blockchain?: string;
  token?: string;
}

export interface TopUpInformationDto {
  type: string;
}

/** Top-up information for IBAN. */
export type TopUpInformationIban = TopUpInformationDto & {
  iban?: string;
  bank_name?: string;
  account_holder?: string;
  reference?: string;
};

/** Transaction data. */
export interface Transaction {
  id?: string;
  /**
   * Amount.
   * @example 100
   */
  amount: number;
  /**
   * Currency.
   * @example "USD"
   */
  currency: string;
  /**
   * Amount.
   * @example 100
   */
  billAmount: number;
  /**
   * Currency.
   * @example "USD"
   */
  billCurrency: string;
  transactionType: string;
  /**
   * Fee.
   * @example 1
   */
  fee: number;
  /** @format date-time */
  creationDate?: string;
  /** @format date-time */
  processingDate?: string;
  status?: TransactionStatusDto;
  merchantData?: MerchantDataDto;
  specificData?: FinciTransactionSpecificData | PfsTransactionSpecificData | ReapTransactionSpecificData;
  /** Card data. */
  card?: Card;
}

export enum TransactionStatus {
  INIT = 'INIT',
  AUTHORIZED = 'AUTHORIZED',
  CLEARED = 'CLEARED',
  DECLINED = 'DECLINED',
  VOID = 'VOID',
  TIMEOUT = 'TIMEOUT',
  UNKNOWN = 'UNKNOWN',
  UNSENT = 'UNSENT',
}

export enum TransactionStatusDto {
  INIT = 'INIT',
  AUTHORIZED = 'AUTHORIZED',
  CLEARED = 'CLEARED',
  DECLINED = 'DECLINED',
  VOID = 'VOID',
  UnknownDefaultOpenApi = 'unknown_default_open_api',
}

/** User address */
export interface UserAddress {
  /** @format uuid */
  id?: string;
  /**
   * Country Alpha-3 code
   * @minLength 3
   * @maxLength 3
   * @example "GBR"
   */
  country?: string;
  /**
   * Post code
   * @example "SW1A 1AA"
   */
  postCode?: string;
  /**
   * State
   * @example "MA"
   */
  state?: string;
  /**
   * Town
   * @example "London"
   */
  town?: string;
  /**
   * Street
   * @minLength 0
   * @maxLength 35
   * @example "Downing Street"
   */
  street?: string;
  /**
   * Sub street (second line of postal address)
   * @minLength 0
   * @maxLength 35
   * @example "10"
   */
  subStreet?: string;
  /**
   * (Optional) Building name
   * @minLength 0
   * @maxLength 35
   * @example "Buckingham Palace"
   */
  buildingName?: string;
  /**
   * (Optional) Flat number
   * @example "1"
   */
  flatNumber?: string;
  /**
   * (Optional) Building number
   * @example "10"
   */
  buildingNumber?: string;
  confirmed?: boolean;
}

/** User Personally Identifiable Information (PII) data. */
export type UserPII = BasePIIDto & {
  /**
   * First name.
   * @minLength 0
   * @maxLength 20
   */
  firstName: string;
  /** Middle name. */
  middleName?: string;
  /**
   * Last name.
   * @minLength 0
   * @maxLength 20
   */
  lastName: string;
  /**
   * Date of birth.
   * @example "1990-01-20"
   */
  dob: string;
  /** User address */
  address: UserAddress;
  /** Phone number. */
  phone?: string;
  /** Email address. */
  email?: string;
  /**
   * Document type. Awarded values: `Passport`, `NationalId`, `DriversLicense`
   * @example "Passport"
   */
  documentType: string;
  /** Document number. */
  documentNumber: string;
} & BasePII & {
    /**
     * @minLength 0
     * @maxLength 20
     */
    firstName: string;
    middleName?: string;
    /**
     * @minLength 0
     * @maxLength 20
     */
    lastName: string;
    dob: string;
    /** User address */
    address: UserAddress;
    phone?: string;
    email?: string;
    documentType: string;
    documentNumber: string;
  };

/** Card sensitive details. */
type BaseCardSensitiveDetails = object;

type BaseCardSensitiveDetailsTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Top-up information. */
type BaseTopUpInformation = object;

type BaseTopUpInformationTypeMapping<Key, Type> = {
  type: Key;
} & Type;

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.stg.darknet.piefi.app/card-holder',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Card-holder service
 * @version 0.0.11-fa171ac5
 * @baseUrl https://api.stg.darknet.piefi.app/card-holder
 *
 * API for interaction with different card-issuing providers.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  adminCards = {
    /**
     * @description Update card phone number.
     *
     * @tags Admin Cards
     * @name AdminUpdatePhone
     * @summary Update card phone
     * @request PUT:/admin/cardholder/card/{cardId}/phone
     * @secure
     */
    adminUpdatePhone: (cardId: string, data: CardPhoneUpdate, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/phone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update card email.
     *
     * @tags Admin Cards
     * @name AdminUpdateEmail
     * @summary Update card email
     * @request PUT:/admin/cardholder/card/{cardId}/email
     * @secure
     */
    adminUpdateEmail: (cardId: string, data: CardEmailUpdate, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description List all cards.
     *
     * @tags Admin Cards
     * @name AdminListCards
     * @summary List cards
     * @request GET:/admin/cardholder/card
     * @secure
     */
    adminListCards: (
      query?: {
        status?: CardStatus;
        cardType?: string;
        search?: string;
        userId?: string;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminCard, ApiErrorResponse>({
        path: `/admin/cardholder/card`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Create card for user. PII information is required for some providers. If service is in standalone mode, PII must be provided in encrypted form. See data encryption manual page.
     *
     * @tags Admin Cards
     * @name AdminCreateCard
     * @summary Create card
     * @request POST:/admin/cardholder/card
     * @secure
     */
    adminCreateCard: (
      query: {
        userId: string;
        partnerId: string;
      },
      data: CreateCard,
      params: RequestParams = {}
    ) =>
      this.request<AdminCard, ApiErrorResponse>({
        path: `/admin/cardholder/card`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get top-up information. Only available for Prepaid cards on certain providers. Check agreement with provider.
     *
     * @tags Admin Cards
     * @name AdminGetTopUpInformation
     * @summary Get top-up information
     * @request POST:/admin/cardholder/card/{cardId}/top-up-information
     * @secure
     */
    adminGetTopUpInformation: (cardId: string, params: RequestParams = {}) =>
      this.request<TopUpInformation, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/top-up-information`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Prefund card with balance on master account. Only available for Prepaid cards on certain providers. Check agreement with provider.
     *
     * @tags Admin Cards
     * @name AdminPrefund
     * @summary Prefund card
     * @request POST:/admin/cardholder/card/{cardId}/prefund
     * @secure
     */
    adminPrefund: (cardId: string, data: CardPrefund, params: RequestParams = {}) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/prefund`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Reset card PIN. May be encrypted.
     *
     * @tags Admin Cards
     * @name AdminResetPin
     * @summary Reset card PIN
     * @request POST:/admin/cardholder/card/{cardId}/pin/reset
     * @secure
     */
    adminResetPin: (cardId: string, data: PinReset, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/pin/reset`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Change card status.
     *
     * @tags Admin Cards
     * @name AdminUpdateCardStatus
     * @summary Change card status
     * @request POST:/admin/cardholder/card/{cardId}/change-status
     * @secure
     */
    adminUpdateCardStatus: (
      cardId: string,
      query?: {
        requiredStatus?: CardStatus;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/change-status`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get card details.
     *
     * @tags Admin Cards
     * @name AdminGetCard
     * @summary Get card details
     * @request GET:/admin/cardholder/card/{cardId}
     * @secure
     */
    adminGetCard: (cardId: string, params: RequestParams = {}) =>
      this.request<AdminCard, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get card transactions.
     *
     * @tags Admin Cards
     * @name AdminGetCardTransactions
     * @summary Get card transactions
     * @request GET:/admin/cardholder/card/{cardId}/transactions
     * @secure
     */
    adminGetCardTransactions: (
      cardId: string,
      query?: {
        status?: TransactionStatus[];
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /** @default "-creationDate" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseTransactionDto, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get card sensitive details. May be encrypted.
     *
     * @tags Admin Cards
     * @name AdminGetCardSensitiveDetails
     * @summary Get card sensitive details
     * @request GET:/admin/cardholder/card/{cardId}/sensitive-details
     * @secure
     */
    adminGetCardSensitiveDetails: (cardId: string, params: RequestParams = {}) =>
      this.request<CardSensitiveDetails, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/sensitive-details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get card PIN. May be encrypted.
     *
     * @tags Admin Cards
     * @name AdminGetPin
     * @summary Get card PIN
     * @request GET:/admin/cardholder/card/{cardId}/pin
     * @secure
     */
    adminGetPin: (cardId: string, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/pin`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get card details.
     *
     * @tags Admin Cards
     * @name AdminGetCardDetails
     * @summary Get card details
     * @request GET:/admin/cardholder/card/{cardId}/details
     * @secure
     */
    adminGetCardDetails: (cardId: string, params: RequestParams = {}) =>
      this.request<CardDetails, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/details`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get card balance. Only available for Prepaid cards on certain providers. Check agreement with provider.
     *
     * @tags Admin Cards
     * @name AdminGetCardBalance
     * @summary Get card balance
     * @request GET:/admin/cardholder/card/{cardId}/balance
     * @secure
     */
    adminGetCardBalance: (cardId: string, params: RequestParams = {}) =>
      this.request<Balance, ApiErrorResponse>({
        path: `/admin/cardholder/card/${cardId}/balance`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  invoice = {
    /**
     * @description webhook controller for card request invoice.
     *
     * @tags Invoice
     * @name InvoiceWebhook
     * @summary Card request invoice webhook.
     * @request POST:/invoice/webhook
     */
    invoiceWebhook: (
      query: {
        invoiceDto: OriginalInvoiceDtoDto;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/invoice/webhook`,
        method: 'POST',
        query: query,
        ...params,
      }),
  };
  cardRequests = {
    /**
     * @description List all card request for current user. Returns empty list if no card requests found.
     *
     * @tags Card Requests
     * @name ListCardRequests
     * @summary List card requests.
     * @request GET:/cardrequest
     * @secure
     */
    listCardRequests: (params: RequestParams = {}) =>
      this.request<CardRequest[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/cardrequest`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create card request for current user. PII information is required for some providers. If service is in standalone mode, PII must be provided in encrypted form. See data encryption guide page.  Card type may be different for different providers. Check agreement with provider. `VIRTUAL` card - card for online payments only, some providers allows to issue physical card later. `PHYSICAL` card - card for online and offline payments. May be issued for some providers only. Physical card delivery address is required in PII
     *
     * @tags Card Requests
     * @name CreateCardRequest
     * @summary Create card request.
     * @request POST:/cardrequest
     * @secure
     */
    createCardRequest: (data: CreateCardRequest, params: RequestParams = {}) =>
      this.request<CardRequest, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/cardrequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  webhook = {
    /**
     * @description Process webhook event
     *
     * @tags Webhook
     * @name ProcessWebhook
     * @summary Provider webhook.
     * @request POST:/cardholder/webhook/{provider}
     */
    processWebhook: (provider: ProviderDto, data: JsonNode, params: RequestParams = {}) =>
      this.request<ProcessWebhook200ResponseDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/cardholder/webhook/${provider}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Simulate callback
     *
     * @tags Webhook
     * @name SimulateCallback
     * @summary Simulate callback.
     * @request POST:/cardholder/webhook/simulateCallback
     */
    simulateCallback: (data: AuthorizationCallbackRequestDto, params: RequestParams = {}) =>
      this.request<AuthorizationCallbackResponseDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/cardholder/webhook/simulateCallback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  cards = {
    /**
     * @description List all cards for current user. Returns empty list if no cards found.
     *
     * @tags Cards
     * @name ListCards
     * @summary List cards.
     * @request GET:/cardholder/card
     * @secure
     */
    listCards: (params: RequestParams = {}) =>
      this.request<Card[], ApiErrorResponseDto | ApiErrorResponse>({
        path: `/cardholder/card`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create card for current user. PII information is required for some providers. If service is in standalone mode, PII must be provided in encrypted form. See data encryption guide page.  Card type may be different for different providers. Check agreement with provider. `VIRTUAL` card - card for online payments only, some providers allows to issue physical card later. `PHYSICAL` card - card for online and offline payments. May be issued for some providers only. Physical card delivery address is required in PII
     *
     * @tags Cards
     * @name CreateCard
     * @summary Create card.
     * @request POST:/cardholder/card
     * @deprecated
     * @secure
     */
    createCard: (data: CreateCard, params: RequestParams = {}) =>
      this.request<Card, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/cardholder/card`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Reset card PIN. Returns new PIN. May return encrypted object that must be decoded by user secret phrase.
     *
     * @tags Cards
     * @name ResetPin
     * @summary Reset card PIN.
     * @request POST:/cardholder/card/{cardId}/pin/reset
     * @secure
     */
    resetPin: (cardId: string, data: PinResetDto, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/pin/reset`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get current card limits as cap and current state if provider supports
     *
     * @tags Cards
     * @name GetCardLimits
     * @summary Get card limits.
     * @request GET:/cardholder/card/{cardId}/limits
     * @secure
     */
    getCardLimits: (cardId: string, params: RequestParams = {}) =>
      this.request<CardLimits, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/limits`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update card limits as cap and current state if provider supports
     *
     * @tags Cards
     * @name UpdateCardLimits
     * @summary Update card limits.
     * @request POST:/cardholder/card/{cardId}/limits
     * @secure
     */
    updateCardLimits: (cardId: string, data: CardLimit, params: RequestParams = {}) =>
      this.request<CardLimits, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/limits`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change the card type, allows to change the card type from virtual to physical and request delivery.
     *
     * @tags Cards
     * @name ChangeCardType
     * @summary Change card type.
     * @request POST:/cardholder/card/{cardId}/change-type
     * @secure
     */
    changeCardType: (cardId: string, data: ChangeCardType, params: RequestParams = {}) =>
      this.request<Card, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/change-type`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change card status.
     *
     * @tags Cards
     * @name UpdateCardStatus
     * @summary Change card status.
     * @request POST:/cardholder/card/{cardId}/change-status
     * @secure
     */
    updateCardStatus: (
      cardId: string,
      query?: {
        requiredStatus?: CardStatusDto;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/change-status`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Activate physical card, not applicable for virtual cards and some providers.
     *
     * @tags Cards
     * @name ActivateCard
     * @summary Activate card.
     * @request POST:/cardholder/card/{cardId}/activate
     * @secure
     */
    activateCard: (cardId: string, data: ActivateCard, params: RequestParams = {}) =>
      this.request<Card, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/activate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get single card info.
     *
     * @tags Cards
     * @name GetCard
     * @summary Get card.
     * @request GET:/cardholder/card/{cardId}
     * @secure
     */
    getCard: (cardId: string, params: RequestParams = {}) =>
      this.request<Card, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get card transactions.
     *
     * @tags Cards
     * @name GetCardTransactionsByCard
     * @summary Get card transactions.
     * @request GET:/cardholder/card/{cardId}/transactions
     * @secure
     */
    getCardTransactionsByCard: (
      cardId: string,
      query?: {
        status?: TransactionStatusDto;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /**
         * @format int32
         * @default 10
         */
        size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseTransactionDto, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get top-up information. Only available for Prepaid cards on certain providers. Check agreement with provider.
     *
     * @tags Cards
     * @name GetTopUpInformation
     * @summary Get top-up information.
     * @request GET:/cardholder/card/{cardId}/top-up-information
     * @secure
     */
    getTopUpInformation: (cardId: string, params: RequestParams = {}) =>
      this.request<TopUpInformationDto[], ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/top-up-information`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get card sensitive details.  May return encrypted object that must be decoded by user secret phrase.
     *
     * @tags Cards
     * @name GetCardSensitiveDetails
     * @summary Get card sensitive details.
     * @request GET:/cardholder/card/{cardId}/sensitive-details
     * @secure
     */
    getCardSensitiveDetails: (cardId: string, params: RequestParams = {}) =>
      this.request<CardSensitiveDetailsDto, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/sensitive-details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get card PIN. May return encrypted object that must be decoded by user secret phrase.
     *
     * @tags Cards
     * @name GetPin
     * @summary Get card PIN.
     * @request GET:/cardholder/card/{cardId}/pin
     * @secure
     */
    getPin: (cardId: string, params: RequestParams = {}) =>
      this.request<string, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/pin`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get card sensitive details.
     *
     * @tags Cards
     * @name GetCardDetails
     * @summary Get card details.
     * @request GET:/cardholder/card/{cardId}/details
     * @secure
     */
    getCardDetails: (cardId: string, params: RequestParams = {}) =>
      this.request<CardDetails, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get card balance. Only available for Prepaid cards on certain providers. Check agreement with provider.
     *
     * @tags Cards
     * @name GetCardBalance
     * @summary Get card balance.
     * @request GET:/cardholder/card/{cardId}/balance
     * @secure
     */
    getCardBalance: (cardId: string, params: RequestParams = {}) =>
      this.request<BalanceDto, ApiErrorResponseDto>({
        path: `/cardholder/card/${cardId}/balance`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  transactions = {
    /**
     * @description Get card transactions for the specified period. If no period is specified, transactions from the current day are returned.
     *
     * @tags Transactions
     * @name GetCardTransactions
     * @summary Get card transactions.
     * @request GET:/transaction
     * @secure
     */
    getCardTransactions: (
      query: {
        /** @format uuid */
        cardId: string;
        status?: TransactionStatusDto[];
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /** @default "-creationDate" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseTransactionDto, ApiErrorResponseDto | ApiErrorResponse>({
        path: `/transaction`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  cardOffers = {
    /**
     * @description List all card offers for current user. Returns empty list if no cards offers found.
     *
     * @tags Card Offers
     * @name ListCardOffers
     * @summary List card offers.
     * @request GET:/cardoffer
     * @secure
     */
    listCardOffers: (params: RequestParams = {}) =>
      this.request<CardOffer[], ApiErrorResponseDto>({
        path: `/cardoffer`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  adminTransactions = {
    /**
     * @description Get card transactions for the specified period. If no period is specified, transactions from the current day are returned.
     *
     * @tags Admin Transactions
     * @name AdminGetTransactions
     * @summary Get card transactions
     * @request GET:/admin/transactions
     * @secure
     */
    adminGetTransactions: (
      query?: {
        status?: TransactionStatus[];
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
        /** @default "-creationDate" */
        sort?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseTransactionDto, ApiErrorResponse>({
        path: `/admin/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  adminProviderConfig = {
    /**
     * @description Get Master account balance
     *
     * @tags Admin Provider Config
     * @name AdminGetMasterAccountBalance
     * @summary Get Master account balance
     * @request GET:/admin/provider/{provider}/balance
     * @secure
     */
    adminGetMasterAccountBalance: (provider: Provider, params: RequestParams = {}) =>
      this.request<AccountBalance, ApiErrorResponse>({
        path: `/admin/provider/${provider}/balance`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
