/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AuthApi, WalletApi } from 'api';
import { AdminAccountUser, Balance } from 'api/wallet';
import { URL_SEARCH_PARAMS } from 'assets/config';
import BigNumber from 'bignumber.js';
import { makeAutoObservable, runInAction } from 'mobx';
import { TransactionAccountModel } from 'models/TransactionsModel';
import { AdminBalanceLogModel } from 'models/request/AdminBalanceLogModel';
import { CurrencyStore } from 'stores/CurrencyStore';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';

export class AccountDetailsPageStore {
  constructor(
    private authApi: AuthApi,
    private walletApi: WalletApi,
    private currencyStore: CurrencyStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public partnerId: number = 0;
  public accountId: string = '';
  public account: AdminAccountUser | null = null;
  public userId: string = '';

  private _balances = {
    loading: false,
    total: 0,
    balances: [] as Balance[],
    fetch: async (accountId: string) => {
      try {
        runInAction(() => {
          this._balances.loading = true;
        });
        const result = await this.walletApi.admin.adminGetAccountBalance(
          accountId,
          undefined,
          {
            headers: { PartnerId: this.partnerId },
          }
        );

        runInAction(() => {
          this._balances.loading = true;
          this._balances.total = result.data.reduce((acc, item) => {
            return (
              acc +
              Number(
                this.currencyStore.exchangeCurrencies({
                  from: item.currency,
                  to: 'USDT',
                  amount: BigNumber(item.balance),
                })?.targetAmount || 0
              )
            );
          }, 0);

          this._balances.balances = result.data;
        });
      } catch (error) {
        runInAction(() => {
          this._balances.loading = false;
        });
      }
    },
  };

  private _transactions = new CollectionWithPages<
    TransactionAccountModel,
    AdminBalanceLogModel
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.walletApi.admin.adminGetBalanceLog(
        {
          userId: this.userId,
          accounts: [this.accountId],

          ...params,
        },
        {
          headers: { PartnerId: this.partnerId },
          paramsSerializer: {
            indexes: null,
          },
        }
      );

      const items = result.data.data.map((x) => new TransactionAccountModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },

    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  private getAccount = async (accountId: string, userId: string) => {
    const result = await this.walletApi.admin.adminListAccountByUser(
      { userId },
      {
        headers: { PartnerId: this.partnerId },
      }
    );
    runInAction(() => {
      this.account =
        result.data.find((item) => item.accountId === accountId) || null;
    });
  };

  get transactions() {
    return this._transactions;
  }
  get balances() {
    return this._balances;
  }

  async init(accountId: string, partnerId: number, userId: string) {
    runInAction(() => {
      this.partnerId = partnerId;
      this.accountId = accountId;
      this.userId = userId;
    });

    this.getAccount(accountId, userId);
    this._balances.fetch(accountId);
  }
}
