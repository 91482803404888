/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { BaseInvoiceModel } from 'models/InvoicesModel';
import styles from './index.module.css';
import { formattedDate } from 'utils/dates';
import Button from 'components/core/button';

type Props = {
  invoice: BaseInvoiceModel;
  onPay: (invoice: BaseInvoiceModel) => void;
};

const SidebarInvoiceInfo = observer(({ invoice, onPay }: Props) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <h5>
        {intl.formatMessage({
          id: 'sidebar.transactionReview.invoiceDetails',
          defaultMessage: 'Invoice details',
        })}
      </h5>
      <div className={styles.section}>
        <ul className={styles.list}>
          <li>
            <span className={styles.description}>
              {intl.formatMessage({
                id: 'sidebar.invoiceInfo.invoiceId',
                defaultMessage: 'Invoice ID',
              })}
            </span>
            <span className={styles.value}>{invoice.id}</span>
          </li>

          <li>
            <span className={styles.description}>
              {intl.formatMessage({
                id: 'sidebar.invoiceInfo.invoiceId',
                defaultMessage: 'Type',
              })}
            </span>
            <span className={styles.value}>{invoice.type}</span>
          </li>

          <li>
            <span className={styles.description}>
              {intl.formatMessage({
                id: 'sidebar.invoiceInfo.amount',
                defaultMessage: 'Amount',
              })}
            </span>
            <span className={styles.value}>
              {invoice.amount.toFixed()} {invoice.currency}
            </span>
          </li>

          {invoice.status && (
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.invoiceInfo.status',
                  defaultMessage: 'Status',
                })}
              </span>
              <span className={styles.value}>{invoice.status}</span>
            </li>
          )}

          {invoice.paymentDate && (
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.invoiceInfo.status',
                  defaultMessage: 'Status',
                })}
              </span>
              <span className={styles.value}>
                {formattedDate(invoice.paymentDate)}
              </span>
            </li>
          )}

          {invoice.nextPaymentDate && (
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.invoiceInfo.next_payment_date',
                  defaultMessage: 'Next Payment Date',
                })}
              </span>
              <span className={styles.value}>
                {formattedDate(invoice.nextPaymentDate)}
              </span>
            </li>
          )}

          {invoice.recurrentInvoiceInterval && (
            <li>
              <span className={styles.description}>
                {intl.formatMessage({
                  id: 'sidebar.invoiceInfo.recurrent_invoice_interval',
                  defaultMessage: 'Recurrent Invoice Interval',
                })}
              </span>
              <span className={styles.value}>
                {invoice.recurrentInvoiceInterval}
              </span>
            </li>
          )}
        </ul>
        {invoice.type === 'ORIGINAL' &&
          (invoice.status === 'INIT' ||
            invoice.status === 'FAILED' ||
            invoice.status === 'REJECTED') && (
            <Button variant='primary' onClick={() => onPay(invoice)}>
              Pay Invoice
            </Button>
          )}
      </div>

      {!!invoice.payments?.length && (
        <>
          <h5>
            {intl.formatMessage({
              id: 'sidebar.transactionReview.invoice_details',
              defaultMessage: 'Payment details',
            })}
          </h5>

          {invoice.payments.map(({ type, id, status }) => (
            <div className={styles.section}>
              <ul className={styles.list}>
                <li>
                  <span className={styles.description}>
                    {intl.formatMessage({
                      id: 'sidebar.invoiceInfo.id',
                      defaultMessage: 'Id',
                    })}
                  </span>
                  <span className={styles.value}>{id}</span>
                </li>
                <li>
                  <span className={styles.description}>
                    {intl.formatMessage({
                      id: 'sidebar.invoiceInfo.type',
                      defaultMessage: 'Type',
                    })}
                  </span>
                  <span className={styles.value}>{type}</span>
                </li>
                <li>
                  <span className={styles.description}>
                    {intl.formatMessage({
                      id: 'sidebar.invoiceInfo.status',
                      defaultMessage: 'Status',
                    })}
                  </span>
                  <span className={styles.value}>{status}</span>
                </li>
              </ul>
            </div>
          ))}
        </>
      )}
      {!!invoice.linkedInvoices?.length && (
        <>
          <h5>
            {intl.formatMessage({
              id: 'sidebar.transactionReview.linked_invoices',
              defaultMessage: 'Linked Invoices details',
            })}
          </h5>

          {invoice.linkedInvoices.map(
            (
              { status, id, amount, type, currency, externalClientId },
              index
            ) => (
              <div className={styles.section}>
                <ul className={styles.list}>
                  <li>
                    <span className={styles.description}>
                      {intl.formatMessage({
                        id: 'sidebar.invoiceInfo.id',
                        defaultMessage: 'ID',
                      })}
                    </span>
                    <span className={styles.value}>{id}</span>
                  </li>
                  <li>
                    <span className={styles.description}>
                      {intl.formatMessage({
                        id: 'sidebar.invoiceInfo.status',
                        defaultMessage: 'Status',
                      })}
                    </span>
                    <span className={styles.value}>{status}</span>
                  </li>
                  <li>
                    <span className={styles.description}>
                      {intl.formatMessage({
                        id: 'sidebar.invoiceInfo.amount',
                        defaultMessage: 'Amount',
                      })}
                    </span>
                    <span className={styles.value}>
                      {amount.toFixed()} {currency}
                    </span>
                  </li>
                  <li>
                    <span className={styles.description}>
                      {intl.formatMessage({
                        id: 'sidebar.invoiceInfo.type',
                        defaultMessage: 'Type',
                      })}
                    </span>
                    <span className={styles.value}>{type}</span>
                  </li>
                  {externalClientId && (
                    <li>
                      <span className={styles.description}>
                        {intl.formatMessage({
                          id: 'sidebar.invoiceInfo.external_client_dd',
                          defaultMessage: 'External Client Id',
                        })}
                      </span>
                      <span className={styles.value}>{externalClientId}</span>
                    </li>
                  )}
                </ul>

                {type === 'ORIGINAL' &&
                  (status === 'INIT' ||
                    status === 'FAILED' ||
                    status === 'REJECTED') && (
                    <Button
                      variant='primary'
                      onClick={() =>
                        onPay(invoice.linkedInvoices?.[index] as any)
                      }
                    >
                      Pay Invoice
                    </Button>
                  )}
              </div>
            )
          )}
        </>
      )}
    </React.Fragment>
  );
});

export const useSidebarInvoiceInfo = ({
  onPay,
}: {
  onPay: (invoice: BaseInvoiceModel) => void;
}) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(
    (invoice: BaseInvoiceModel) => {
      layoutStore.toggleSidebar(
        true,
        intl.formatMessage({
          id: 'sidebar.invoice_information',
          defaultMessage: `Invoice Information`,
        }),
        <SidebarInvoiceInfo invoice={invoice} onPay={onPay} />
      );
    },
    [intl, layoutStore]
  );

  return {
    showInvoiceInfo: showSidebar,
  };
};
