/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import Image from '../image';
import classNames from 'classnames';

import { useCallback } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow.svg';
import { Select as SelectAnt } from 'antd';
import { SelectItemModel } from 'models/form/SelectItemModel';

type SelectSize = 'small' | 'middle' | 'large';

type SelectVariants = 'outlined' | 'borderless' | 'filled';

export type SelectProps<T> = {
  placeholder?: string;
  disabled?: boolean;
  options: SelectItemModel<T>[];
  value?: SelectItemModel<T>;
  onChange?: (value?: SelectItemModel<T>) => void;
  size?: SelectSize;
  variant?: SelectVariants;
  className?: string;
  showSearch?: boolean;
  darkMode?: boolean;
  error?: string;
};

const Select = <T extends object | string | number | boolean>(
  props: SelectProps<T>
) => {
  const handleSelectChange = useCallback(
    (id?: string) => {
      const selectedItem = props.options.find((x) => x.id === id);

      props.onChange?.(selectedItem);
    },
    [props]
  );

  return (
    <div
      className={classNames(styles.select_container, props.className, {
        'dark-mode': props.darkMode,
      })}
    >
      <SelectAnt
        showSearch={props.showSearch}
        placeholder={props.placeholder}
        onChange={handleSelectChange}
        suffixIcon={<ArrowIcon />}
        value={props.value?.id}
        size={props.size || 'large'}
        variant={props.variant || 'outlined'}
        optionLabelProp='children'
      >
        {props.options.map((option) => (
          <SelectAnt.Option
            key={option.id}
            value={option.id}
            disabled={option.disabled || false}
          >
            {!!option.icon && (
              <div className='ant-select-item-icon'>
                {typeof option.icon === 'string' ? (
                  <Image
                    src={option.icon}
                    alt={option.label.toString()}
                    width={15}
                    height={15}
                  />
                ) : (
                  option.icon
                )}
              </div>
            )}
            {option.label}
          </SelectAnt.Option>
        ))}
      </SelectAnt>
      {props.error && <span className={styles.error}>{props.error}</span>}
    </div>
  );
};

export default Select;
