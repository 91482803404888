/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import styles from './index.module.css';
import { useUserDetailsPageStore } from 'modules/user-details/provider/StoreProvider';
import Button from 'components/core/button';
import { Spin } from 'antd';
import { UserDetailsPageStore } from 'modules/user-details/store/UserDetailsPageStore';
import ReactJson from 'react-json-view';

type Props = {
  store: UserDetailsPageStore;
  verificationId: string;
};

const ModalSyncKyb = observer(({ store, verificationId }: Props) => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();
  const { error, syncKybStatus, syncKybData } = store;

  const onSubmit = useCallback(async () => {
    await store.submitVerificationKyb(verificationId).then(() =>
      layoutStore.toggleModal(false)
    )
  }, [store, verificationId, layoutStore]);

  const isRequestProcessing = useCallback(() => {
    return syncKybStatus === 'processing' || syncKybStatus === null;
  }, [syncKybStatus]);

  return (
    <React.Fragment>
      {!error && isRequestProcessing() && (
        <div className={styles.spinner}>
          <p>
            {intl.formatMessage({
              id: 'page.user_details.modal.syncKyb.syncing',
              defaultMessage: 'Syncing',
            })}
          </p>
          <Spin />
        </div>
      )}

      {error && <div className={styles.error}>{error}</div>}

      {!isRequestProcessing() && (
        <>
          <p className={styles.success}>
            {intl.formatMessage({
              id: 'page.user_details.modal.syncKyb.syncComplete',
              defaultMessage: 'Sync Complete',
            })}
          </p>

          {syncKybData && <ReactJson theme='rjv-default' src={syncKybData} />}
        </>
      )}

      {(error || !isRequestProcessing()) && (
        <>
          <Button className={styles.btn} variant='positive' onClick={onSubmit}>
            {intl.formatMessage({
              id: 'page.user_details.modal.syncKyb.submit',
              defaultMessage: 'Submit',
            })}
          </Button>
        </>
      )}
    </React.Fragment>
  );
});

export const useModalSyncKyb = () => {
  const intl = useIntl();
  const { layoutStore } = useBaseStores();
  const userDetailsPageStore = useUserDetailsPageStore();

  const showSyncKybModal = useCallback((verificationId: string) => {
    layoutStore.toggleModal(
      true,
      intl.formatMessage({
        id: 'page.user_details.modal.syncKyb.syncData',
        defaultMessage: 'Sync Data',
      }),
      <ModalSyncKyb
        store={userDetailsPageStore}
        verificationId={verificationId}
      />
    );
  }, [intl, layoutStore, userDetailsPageStore]);

  return {
    showSyncKybModal,
  };
};
