/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Admin Quest */
export interface AdminQuest {
  /** @format uuid */
  id?: string;
  questId?: string;
  name?: string;
  accountId?: string;
  requirement?: JsonNode;
  events?: JsonNode;
  /** @format int64 */
  delay?: number;
  templateUrl?: string;
  currency?: string;
  reward?: number;
  budget?: number;
  budgetBalance?: number;
  /** @format int64 */
  enrolledUsers?: number;
  /** @format int64 */
  activeUsers?: number;
  /** @format int64 */
  completedUsers?: number;
  /** @format int64 */
  claimedUsers?: number;
  status?: QuestStatus;
  active?: boolean;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  expiredAt?: string;
}

/** Admin User quest. */
export interface AdminUserQuest {
  /** Admin Quest */
  quest?: AdminQuest;
  /** @format uuid */
  userId?: string;
  questUrl?: string;
  status?: UserQuestStatus;
  triggeredEvents?: TriggeredEvent[];
}

/** Claim User Quest Reward */
export interface ClaimUserQuestReward {
  /** @format uuid */
  questId?: string;
}

/** Create quest. */
export interface CreateQuest {
  name: string;
  accountId: string;
  events: JsonNode[];
  templateUrl: string;
  budget: number;
  reward: number;
  currency: string;
  /** @format int64 */
  delay: number;
  /** @format date-time */
  startedAt: string;
  /** @format date-time */
  expiredAt: string;
}

/** Create user quest. */
export interface CreateUserQuest {
  /** @format uuid */
  questId?: string;
}

export type JsonNode = object;

export interface PageableResponseAdminQuest {
  data: AdminQuest[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

export interface PageableResponseAdminUserQuest {
  data: AdminUserQuest[];
  /** @format int32 */
  currentPage: number;
  /** @format int64 */
  totalItems: number;
  /** @format int32 */
  totalPages: number;
}

/** Quest */
export interface Quest {
  /** @format uuid */
  id?: string;
  requirement?: JsonNode;
  name?: string;
  currency?: string;
  reward?: number;
  status?: QuestStatus;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  expiredAt?: string;
}

export enum QuestStatus {
  NEW = 'NEW',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  COMPLETED = 'COMPLETED',
}

/** Triggered event. */
export interface TriggeredEvent {
  /** @format uuid */
  id?: string;
  eventId?: string;
  /** @format int64 */
  duration?: number;
  /** @format date-time */
  triggeredAt?: string;
  eventData?: object;
}

/** Update quest. */
export interface UpdateQuest {
  name: string;
  accountId: string;
  events: JsonNode[];
  templateUrl: string;
  budget: number;
  reward: number;
  currency: string;
  /** @format int64 */
  delay: number;
  /** @format date-time */
  startedAt: string;
  /** @format date-time */
  expiredAt: string;
  /** @format uuid */
  id?: string;
  active?: boolean;
}

/** User quest. */
export interface UserQuest {
  /** Quest */
  quest?: Quest;
  /** @format uuid */
  userId?: string;
  questUrl?: string;
  status?: UserQuestStatus;
}

export enum UserQuestStatus {
  NEW = 'NEW',
  STARTED = 'STARTED',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  COMPLETED = 'COMPLETED',
  CLAIMED = 'CLAIMED',
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL:
        axiosConfig.baseURL || 'https://ad3-api.staging.piefi.app/reward',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Quest rewards service
 * @version 0.0.11-b36346ed
 * @baseUrl https://ad3-api.staging.piefi.app/reward
 *
 * API for interaction with quest and receive rewards.
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  adminQuest = {
    /**
     * @description Get quests by page
     *
     * @tags Admin Quest
     * @name GetQuestsByPage
     * @summary Get quests by page
     * @request GET:/admin/quest
     * @secure
     */
    getQuestsByPage: (
      query?: {
        status?: QuestStatus[];
        name?: string;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminQuest, any>({
        path: `/admin/quest`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update quest
     *
     * @tags Admin Quest
     * @name UpdateQuest
     * @summary Update quest
     * @request PUT:/admin/quest
     * @secure
     */
    updateQuest: (data: UpdateQuest, params: RequestParams = {}) =>
      this.request<AdminQuest, any>({
        path: `/admin/quest`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create quest
     *
     * @tags Admin Quest
     * @name CreateQuest
     * @summary Create quest
     * @request POST:/admin/quest
     * @secure
     */
    createQuest: (data: CreateQuest, params: RequestParams = {}) =>
      this.request<AdminQuest, any>({
        path: `/admin/quest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get quest by id
     *
     * @tags Admin Quest
     * @name GetQuestById
     * @summary Get quest by id
     * @request GET:/admin/quest/{questId}
     * @secure
     */
    getQuestById: (questId: string, params: RequestParams = {}) =>
      this.request<AdminQuest, any>({
        path: `/admin/quest/${questId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete quest
     *
     * @tags Admin Quest
     * @name DeleteQuest
     * @summary Delete quest
     * @request DELETE:/admin/quest/{questId}
     * @secure
     */
    deleteQuest: (questId: string, params: RequestParams = {}) =>
      this.request<AdminQuest, any>({
        path: `/admin/quest/${questId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get user quests by quest id and page
     *
     * @tags Admin Quest
     * @name GetUserQuestByQuestIdAndIdPage
     * @summary Get user quests by quest id and page
     * @request GET:/admin/quest/users
     * @secure
     */
    getUserQuestByQuestIdAndIdPage: (
      query: {
        /** @format uuid */
        questId: string;
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminUserQuest, any>({
        path: `/admin/quest/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get user quests progress for current user
     *
     * @tags Admin Quest
     * @name GetUserQuestsByUserIdAndStatus
     * @summary Get user quests progress for current user
     * @request GET:/admin/quest/user/status
     * @secure
     */
    getUserQuestsByUserIdAndStatus: (
      query: {
        /** @format uuid */
        userId: string;
        /** @default [] */
        status?: UserQuestStatus[];
        /**
         * @format int32
         * @default 100
         */
        size?: number;
        /**
         * @format int32
         * @default 0
         */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PageableResponseAdminUserQuest, any>({
        path: `/admin/quest/user/status`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  webhook = {
    /**
     * No description
     *
     * @tags Webhook
     * @name ProcessWebhook
     * @request POST:/webhook
     */
    processWebhook: (params: RequestParams = {}) =>
      this.request<JsonNode, any>({
        path: `/webhook`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  userQuest = {
    /**
     * @description Get quests for current user
     *
     * @tags User Quest
     * @name GetUserQuestsByUserId
     * @summary Get quests for current user
     * @request GET:/user/quest
     * @secure
     */
    getUserQuestsByUserId: (params: RequestParams = {}) =>
      this.request<UserQuest[], any>({
        path: `/user/quest`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get individual quest url
     *
     * @tags User Quest
     * @name GetUserQuestUrl
     * @summary Get individual quest url
     * @request POST:/user/quest
     * @secure
     */
    getUserQuestUrl: (data: CreateUserQuest, params: RequestParams = {}) =>
      this.request<UserQuest, any>({
        path: `/user/quest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Claim quest reward
     *
     * @tags User Quest
     * @name ClaimUserQuestReward
     * @summary Claim quest reward
     * @request POST:/user/quest/claim
     * @secure
     */
    claimUserQuestReward: (
      data: ClaimUserQuestReward,
      params: RequestParams = {}
    ) =>
      this.request<JsonNode, any>({
        path: `/user/quest/claim`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
