/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl } from 'react-intl';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { TableProps } from 'antd';

import { formattedDate, FULL_DATE, getRemainingDate } from 'utils/dates';
import { BadgeStatus } from 'entities/quest';
import { cutId } from 'utils/strings';
import { UserEventsQuestsModel } from 'models/UserQuestModel';
import Table from 'components/core/table';
import CopyButton from 'components/core/copy-button';
import { useSidebarJsonViewer } from 'components/sidebars/sidebar-json-viewer';
import Button from 'components/core/button';
import { isEmpty } from 'utils/obj';

type Props = {
  collection: CollectionWithPages<UserEventsQuestsModel, {}>;
};

const QuestsTable = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { showJsonViewerSidebar } = useSidebarJsonViewer();

  const columns: TableProps<UserEventsQuestsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.quests.col.quest_id',
        defaultMessage: 'Quest ID',
      }),
      dataIndex: 'quest',
      key: 'id',
      render: (quest) => (
        <div className='flex items-center gap-2'>
          <span>{cutId(quest.id)}</span>
          <CopyButton text={quest.id} />
        </div>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.event_name',
        defaultMessage: 'Quest Name',
      }),
      dataIndex: 'quest',
      key: 'questName',
      render: (quest) => {
        return <span>{quest.name}</span>;
      },
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.triggered',
        defaultMessage: 'Triggered',
      }),
      dataIndex: 'triggeredEvent',
      key: 'triggered',
      render: (triggeredEvent) => (
        <span>{formattedDate(triggeredEvent.triggeredAt, FULL_DATE)}</span>
      ),
      width: 20,
    },

    {
      title: intl.formatMessage({
        id: 'table.quests.col.duration',
        defaultMessage: 'Duration',
      }),
      dataIndex: 'triggeredEvent',
      key: 'duration',
      render: (triggeredEvent) => (
        <span>
          {triggeredEvent.duration
            ? getRemainingDate(triggeredEvent.duration)
            : '-'}
        </span>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.subscriptions.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeStatus status={status} />,
      width: 50,
    },
    {
      title: intl.formatMessage({
        id: 'table.companies.col.json',
        defaultMessage: 'JSON',
      }),
      dataIndex: 'triggeredEvent',
      key: 'json',
      render: (json) => (
        <Button
          variant={'secondary'}
          size={'small'}
          className='font-semibold w-28'
          disabled={isEmpty(json.eventData as Object)}
          onClick={() =>
            showJsonViewerSidebar(
              json.eventData ? JSON.stringify(json.eventData) : '{}',
              true
            )
          }
        >
          {intl.formatMessage({
            id: 'table.companies.col.checkJson',
            defaultMessage: 'Check JSON',
          })}
        </Button>
      ),
      width: 120,
    },
  ];

  return (
    <Table<UserEventsQuestsModel, {}>
      collection={collection}
      columns={columns}
    />
  );
});

export default QuestsTable;
