/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import BigNumber from 'bignumber.js';

import { AuthApi, WalletApi } from 'api';
import { makeAutoObservable, runInAction } from 'mobx';
import { DashboardStatsModel } from '../models/DashboardStatsModel';
import { CurrencyStore } from 'stores/CurrencyStore';
import { TotalBalanceModel } from 'models/TotalBalanceModel';
import { TotalBalanceReportDto } from 'api/wallet';

export class DashboardPageStore {
  constructor(
    private currencyStore: CurrencyStore,
    private authApi: AuthApi,
    private walletApi: WalletApi
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _totalBalance: TotalBalanceModel[] | null = null;
  private _totalAllBalances: BigNumber = BigNumber(0);
  private _initialized = false;
  private _stats: DashboardStatsModel | null = null;

  get stats() {
    return this._stats;
  }

  get currencies() {
    return this.currencyStore.currencies;
  }

  get totalBalances() {
    return this._totalBalance;
  }

  get totalAllBalances() {
    return this._totalAllBalances;
  }

  get lastUpdatedAt() {
    return this.currencyStore.lastUpdatedAt;
  }

  get initialized() {
    return this._initialized;
  }

  async init(partnerId: number) {
    const [statsResult] = await Promise.all([
      this.authApi.admin.getStats(),
      this.currencyStore.init(),
    ]);

    await this.getTotalBalance(partnerId);

    runInAction(() => {
      this._stats = new DashboardStatsModel(statsResult.data);
      this._initialized = true;
    });
  }

  private async getTotalBalance(partnerId: number) {
    const result = await this.walletApi.admin.adminGetTotalBalance({
      headers: { PartnerId: partnerId },
    });

    const items = result.data
      .map((coin) => {
        const exchangeResult = this.currencyStore.exchangeCurrencies({
          from: coin.currency?.toUpperCase() || '',
          to: 'USD',
          amount: new BigNumber(coin.total || 0),
        });

        if (!exchangeResult) return null;

        const { original, targetAmount } = exchangeResult;

        return {
          ...coin,
          rate: original.eurPrice,
          amount: targetAmount,
        };
      })
      .filter((item) => item !== null);

    const itemsUpdated = items.map(
      (x) => new TotalBalanceModel(x as TotalBalanceReportDto)
    );

    const totalAllBalances = itemsUpdated.reduce((acc, current) => {
      return acc.plus(current?.amount ?? BigNumber(0));
    }, BigNumber(0));

    runInAction(() => {
      this._totalBalance = itemsUpdated;
      this._totalAllBalances = totalAllBalances;
    });
  }
}
