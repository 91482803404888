/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import React from 'react';
import ReactJson from 'react-json-view';

import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { useIntl } from 'react-intl';
import Button from 'components/core/button';
import { dowload } from 'utils/download';

const SidebarJsonViewer = observer(
  ({ source, isDownloaded }: { source: string; isDownloaded?: boolean }) => {
    const intl = useIntl();

    return (
      <React.Fragment>
        <h4 className='font-semibold mb-2'>
          {intl.formatMessage({
            id: 'sidebar.json',
            defaultMessage: 'Source JSON',
          })}
        </h4>

        {source ? (
          <ReactJson theme='rjv-default' src={JSON.parse(source)} />
        ) : null}

        {isDownloaded && (
          <Button
            className='mt-auto'
            variant='primary'
            onClick={() =>
              dowload({
                data: source,
                type: 'application/json',
                filename: 'file.json',
              })
            }
          >
            Download
          </Button>
        )}
      </React.Fragment>
    );
  }
);

export const useSidebarJsonViewer = () => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = (sourceVal: string, isDownloaded?: boolean) => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.data',
        defaultMessage: 'Data',
      }),
      <SidebarJsonViewer source={sourceVal} isDownloaded={isDownloaded} />
    );
  };

  return {
    showJsonViewerSidebar: (sourceVal: string, isDownloaded?: boolean) =>
      showSidebar(sourceVal, isDownloaded),
  };
};
