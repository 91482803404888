/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useState, useEffect } from 'react';
import Button from 'components/core/button';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { SubscriptionsDetailsModel } from 'models/SubscriptionsDetailModel';
import SubscriptionsTable from './subscriptions-types-table';

type SubscriptionDetailsType = {
  items: SubscriptionsDetailsModel[];
  fetchFn: () => Promise<SubscriptionsDetailsModel[]>;
};

type Props = {
  subscriptionDetails: SubscriptionDetailsType;
  onCreate: (subscriptionId: string) => void;
};

const SidebarSubscriptionCreate = observer(
  ({ subscriptionDetails, onCreate }: Props) => {
    const intl = useIntl();

    const [subscriptions, setSubscriptions] = useState<
      SubscriptionsDetailsModel[]
    >([]);

    const [selectedSubscription, setSelectedSubscription] =
      useState<SubscriptionsDetailsModel | null>(null);

    const { layoutStore } = useBaseStores();

    const handleSubscriptionSelect = (value: SubscriptionsDetailsModel) => {
      setSelectedSubscription(value);
    };

    useEffect(() => {
      const fetchDetails = async () => {
        try {
          const subscriptions = await subscriptionDetails.fetchFn();
          setSubscriptions(subscriptions);
        } catch (error) {
          console.error('Error fetching subscription details:', error);
        }
      };

      fetchDetails();
    }, [subscriptionDetails]);

    const onApply = useCallback(() => {
      onCreate(selectedSubscription?.id || '');
      layoutStore.toggleSidebar(false);
    }, [layoutStore, onCreate, selectedSubscription]);

    return (
      <React.Fragment>
        <h5>
          {intl.formatMessage({
            id: 'sidebar.subscriptionCreate.selectType',
            defaultMessage: 'Select Subscription type',
          })}
        </h5>

        <SubscriptionsTable
          collection={subscriptions}
          onRowClick={handleSubscriptionSelect}
        />

        <Button
          className={styles.apply_btn}
          variant='primary'
          onClick={onApply}
          disabled={!selectedSubscription}
        >
          {intl.formatMessage({
            id: 'sidebar.subscriptionCreate.create',
            defaultMessage: 'Create Subscription',
          })}
        </Button>
      </React.Fragment>
    );
  }
);

export const useSidebarSubscriptionCreate = ({
  subscriptionDetails,
  onCreate,
}: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.subscriptionCreate',
        defaultMessage: 'Create Subscription',
      }),
      <SidebarSubscriptionCreate
        subscriptionDetails={subscriptionDetails}
        onCreate={onCreate}
      />
    );
  }, [intl, layoutStore, subscriptionDetails, onCreate]);

  return {
    showSubscriptionCreateSidebar: showSidebar,
  };
};
