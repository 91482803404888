/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import dayjs from 'dayjs';

export const DATE_FORMAT = 'DD MMMM HH:mm:ss';
export const FULL_DATE = 'DD/MM/YYYY';

export const unixTsToDateStr = (timestamp: number, dateFormat: string) => {
  return dayjs.unix(timestamp).format(dateFormat);
};

export const formattedDate = (
  val: string | number,
  dateFormat = 'DD.MM.YYYY HH:mm'
) => dayjs(val).format(dateFormat);

export const getUnixTsNow = () => dayjs().unix();

export const getRemainingDate = (duration: number) => {
  // convert miliseconds to days, hours, minutes
  const days = Math.floor(duration / (24 * 60 * 60)); // количество прошедших дней
  const hours = Math.floor((duration % (24 * 60 * 60)) / (60 * 60)); // количество часов после вычитания дней
  const mins = Math.floor((duration % (60 * 60)) / 60); // количество минут после вычитания часов

  return [
    { value: days, descr: 'd' },
    { value: hours, descr: 'h' },
    { value: mins, descr: 'm' },
  ]
    .filter((item) => item.value)
    .map((item) => `${item.value}${item.descr}`)
    .join(' ');
};
