/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import React, { useMemo, useState } from 'react';
import Button from 'components/core/button';
import FormListSingleSelect from 'components/form/form-list-single-select';

import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { UserModel } from 'models/UserModel';
import { AdminListRequestModel } from 'models/request/AdminListRequestModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { useSetQueryParams } from 'hooks/collection-query-params';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { SelectItemModel } from 'models/form/SelectItemModel';
import { UserStatus } from 'api/auth';

type Props = {
  collection: CollectionWithPages<UserModel, AdminListRequestModel>;
};

const selectOptions = Object.keys(UserStatus).map((status) => ({
  id: status.toLocaleUpperCase(),
  label: status,
  value: status,
}));

const SidebarUsersFilter = observer(({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();
  const { setFilterQueryParams } = useSetQueryParams();

  const defaultStatus = selectOptions.find(
    (item) => collection.filter.status === item.id
  );

  const [selectStatus, setSelectStatus] = useState<
    SelectItemModel<string> | undefined
  >(defaultStatus);

  const onApply = useCallback(() => {
    setFilterQueryParams([
      { id: URL_SEARCH_PARAMS.page, value: '1' },
      {
        id: URL_SEARCH_PARAMS.status,
        value: selectStatus ? selectStatus.id : undefined,
      },
    ]);

    layoutStore.toggleSidebar(false);
  }, [selectStatus, layoutStore, setFilterQueryParams]);

  return (
    <React.Fragment>
      <FormListSingleSelect
        title={intl.formatMessage({
          id: 'sidebar.filters.users.approved.title',
          defaultMessage: 'KYC',
        })}
        options={selectOptions}
        selected={selectStatus}
        onSelect={(val) => setSelectStatus(val)}
        collapse={true}
      />

      <Button className={styles.apply_btn} variant='primary' onClick={onApply}>
        {intl.formatMessage({
          id: 'sidebar.filters.apply',
          defaultMessage: 'Apply',
        })}
      </Button>
    </React.Fragment>
  );
});

export const useSidebarUsersFilter = ({ collection }: Props) => {
  const intl = useIntl();

  const { layoutStore } = useBaseStores();

  const showSidebar = useCallback(() => {
    layoutStore.toggleSidebar(
      true,
      intl.formatMessage({
        id: 'sidebar.filters',
        defaultMessage: 'Filters',
      }),
      <SidebarUsersFilter collection={collection} />
    );
  }, [collection, intl, layoutStore]);

  return {
    showUserFiltersSidebar: showSidebar,
  };
};
