/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { AcquiringApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable } from 'mobx';
import { FilterModel } from 'models/filter/FilterModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';
import { BaseInvoiceModel } from 'models/InvoicesModel';
import { AdminInvoiceModel } from 'models/request/AdminInvoiceModel';
import { InvoiceType } from 'api/acquiring';
import { NotificationStore } from 'stores/NotificationStore';

export class InvoicesPageStore {
  constructor(
    private acquiringApi: AcquiringApi,
    private notificationStore: NotificationStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _invoices = new CollectionWithPages<
    BaseInvoiceModel,
    AdminInvoiceModel
  >({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.acquiringApi.adminInvoices.adminGetInvoices({
        ...params,
      });

      const items = result.data.data.map((x) => new BaseInvoiceModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminInvoiceModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.search
      );

      const userIdStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.userId
      );

      const type = readStrFromQueryParams(urlParams, URL_SEARCH_PARAMS.type);

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.search,
          value: {
            search: searchStr,
          },
        });
      }

      if (userIdStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.userId,
          value: {
            userId: userIdStr,
          },
        });
      }

      if (type) {
        filters.push({
          id: URL_SEARCH_PARAMS.type,
          value: {
            type: type as InvoiceType,
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.search],
    itemsPerPage: 12,
  });

  async adminPayInvoice(invoice: BaseInvoiceModel) {
    try {
      await this.acquiringApi.adminInvoices.adminInvoicePayment({
        invoiceId: invoice.id,
        type: invoice.type,
        externalPayment: {},
      });

      this._invoices.refresh();
      this.notificationStore.notify({
        title: 'Successfully Pay',
        type: 'success',
      });
    } catch {
      this.notificationStore.notify({
        title: 'Failed Pay',
        type: 'error',
      });
    }
  }
  get invoices() {
    return this._invoices;
  }

  async init(props: {
    filter: FilterModel<AdminInvoiceModel>[];
    page: number;
  }) {
    const { filter, page } = props;

    await this._invoices.fetchData(filter, page);
  }
}
